import React from "react";
import lang from "../../helpers/lang-constants";

const language = lang.customer.components.login.disabled_login;

const DisabledLogin = () => (
    <span className="content flex column">
        {language.description_text}
        <br />
        <br />
        {language.label_text}
    </span>
);

export default DisabledLogin;