import { all } from "redux-saga/effects";
import { acceptDirectDebitPaymentWatcher } from "sign-insurance-components";
import { watchUpdatePaymentMethod } from "../actions/invoices.actions";

function* rootSaga() {
  yield all([
    acceptDirectDebitPaymentWatcher(),
    watchUpdatePaymentMethod()
  ]);
}

export default rootSaga;
