import React from "react";
import { connect } from "react-redux";
import { isValid, getFormSyncErrors } from "redux-form";
import {
  actions as stepActions,
  selectors as stepSelectors,
} from "step-counter";
import Register from "register";
import { insuranceActions } from "../../actions";
import { insuranceSelectors } from "../../selectors";
import formatDate from "../../helpers/formatter";
import lang from "../../helpers/lang-constants";

const language = lang.reg.register.notification;
const NUMBER_OF_STEPS = 2;
const { getStep } = stepSelectors;
const { getUserInsurances } = insuranceSelectors;

const getInsuranceEndDate = (userInsurances) => {
  let date;
  let insurance;
  const insurances = userInsurances.first();
  if (insurances) {
    insurance = insurances.first();
    date = insurance.get("endDate");
  }
  return date ? formatDate(date) : null;
};

const RegisterContainer = ({
  step,
  incrementStep,
  decrementStep,
  signInsurance,
  isComepleteFormValid,
  isInitFormValid,
  formSyncErrors,
  userInsurances,
}) => {
  const endDate = getInsuranceEndDate(userInsurances);
  const today = new Date();
  const tomorrow = new Date();
  let insurancePeriod = null;
  tomorrow.setDate(today.getDate() + 1);
  if (endDate) {
    insurancePeriod = `${formatDate(tomorrow)} - ${endDate}`;
  }

  return (
    <Register
      incrementStep={incrementStep}
      decrementStep={decrementStep}
      step={step}
      isValid={isComepleteFormValid && isInitFormValid}
      numberOfSteps={NUMBER_OF_STEPS}
      signInsurances={signInsurance}
      isTermChecked={!formSyncErrors.terms}
      isLoggedIn={Boolean(true)}
      insurancePeriod={insurancePeriod}
      notificationText={language.notification_text}
      insurancePeriodText={language.insurance_period}
    />
  );
};

const mapStateToProps = (state) => ({
  step: getStep(state),
  isComepleteFormValid: isValid("completeUserForm")(state),
  isInitFormValid: isValid("getUserForm")(state),
  formSyncErrors: getFormSyncErrors("completeCustomerForm")(state),
  userInsurances: getUserInsurances(state),
});

const mapDispatchToProps = (dispatch) => ({
  incrementStep: () => dispatch(stepActions.incrementStep()),
  decrementStep: () => dispatch(stepActions.decrementStep()),
  signInsurance: () => dispatch(insuranceActions.signNewInsurance()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterContainer);
