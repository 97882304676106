import { hamburgerConstants } from "../constants";

const { OPEN_HAMBURGER, CLOSE_HAMBURGER } = hamburgerConstants;

const openHamburger = () => ({
  type: OPEN_HAMBURGER
});

const closeHamburger = () => ({
  type: CLOSE_HAMBURGER
});

export default {
  openHamburger,
  closeHamburger
};
