import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/helper/LoadingSpinner";
import { insuranceSelectors } from "../../selectors";

const { getUpdating } = insuranceSelectors;

const LoadingHandler = ({
  isLoadingShopItems,
  isLoadingUserInsurances,
  isSigningInsurance
}) => (
  <LoadingSpinner
    size={25}
    signInsuranceLoading={Boolean(true)}
    isLoading={
      isLoadingUserInsurances || isLoadingShopItems || isSigningInsurance
    }
  />
);

const mapStateToProps = state => ({
  isLoadingShopItems: getUpdating(state, "fetchUserInsurances"),
  isLoadingUserInsurances: getUpdating(state, "fetchShopInsurances"),
  isSigningInsurance: getUpdating(state, "signNewInsurance")
});

LoadingHandler.propTypes = {
  isLoadingShopItems: PropTypes.bool.isRequired,
  isLoadingUserInsurances: PropTypes.bool.isRequired,
  isSigningInsurance: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(LoadingHandler);
