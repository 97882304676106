
export const themes = {
    isNorway: () => {
        return process.env.REACT_APP_THEME === 'NO';
    },
    isDenmark: () => {
        return process.env.REACT_APP_THEME === 'DK';
    },
    isSbf: () => {
        return process.env.REACT_APP_THEME === 'SBF';
    },
    isAssistans: () => {
        return !(themes.isNorway() || themes.isDenmark() || themes.isSbf());
    }
}

export const env = {
    isProduction: () => {
        return process.env.REACT_APP_ENVIRONMENT === 'PROD';
    },
    isDevelopment: () => {
        return process.env.REACT_APP_ENVIRONMENT !== 'PROD' &&
            process.env.REACT_APP_ENVIRONMENT !== 'STAGING';
    },
    isStaging: () => {
        return process.env.REACT_APP_ENVIRONMENT === 'STAGING';
    }
}

let config = {
    isBankdIdLogin: true,
    disableLogin: false
};

if(themes.isNorway()) {
    config.isBankdIdLogin = false;
    config.disableLogin = false;
} else if(themes.isDenmark()) {
    config.isBankdIdLogin = false;
} else if(themes.isAssistans()) {
    config.isBankdIdLogin = true;
} else {
    config.isBankdIdLogin = true;
}


export default config;