import classNames from "classnames";
import Parser from "html-react-parser";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import { bannerActions } from "../../actions";
import { bannerSelectors } from "../../selectors";
import LoadingSpinner from "../helper/LoadingSpinner";

const { getLoading, getBanner } = bannerSelectors;

class InformationBanner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hideSnackBar: false
    };
  }

  componentDidMount() {
    const { fetchBanner } = this.props;
    fetchBanner();
  }

  handleClick = () => {
    this.setState({ hideSnackBar: true });
  };

  render() {
    const { isLoading, banner, ...rest } = this.props;
    const backgroundColor = banner.get("backgroundColor", "");
    const { hideSnackBar } = this.state;
    const style = hideSnackBar ? { display: "none" } : {};
    const isActive = banner.get("active", "");

    return isActive && backgroundColor ? (
      <div className={classNames(rest.className || "")} style={style}>
        <div className="container">
          <div
            className="content flex column"
            style={{
              backgroundColor: backgroundColor
            }}
          >
            <div className="flex row justify-end">
              <i
                className="fal fa-times-circle"
                role="button"
                onClick={this.handleClick}
                onKeyPress={this.handleClick}
                tabIndex={0}
              />
            </div>

            <span className="wrapper flex row wrap justify-center align-center">
              <LoadingSpinner isLoading={isLoading} size={25} />
              {Parser(banner.get("text", ""))}
            </span>
          </div>
        </div>
      </div>
    ) : null;
  }
}

InformationBanner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fetchBanner: PropTypes.func.isRequired,
  banner: ImmutablePropTypes.mapContains({
    active: PropTypes.bool,
    backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }).isRequired
};

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  banner: getBanner(state, "informationBanner")
});

const mapDispatchToProps = dispatch => ({
  fetchBanner: () => dispatch(bannerActions.fetchBanners())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationBanner);
