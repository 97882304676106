import classNames from "classnames";
import React from "react";
import Benefits from "../../components/benefit/Benefits";
import BenefitCard from "../../components/benefit/BenefitCard";

const BenefitsPage = () => (
  <div className={classNames("container")}>
    <Benefits component={BenefitCard} />
  </div>
);

export default BenefitsPage;
