import * as types from "./types";

export default function transformVehicle(vehicle) {
  const newVehicle = Object.assign({}, vehicle);
  for (const key in types) {
    const type = types[key];
    if (type.matchesType(vehicle)) {
      newVehicle.type = type.type;
      newVehicle.isInsurable = type.isInsurable(vehicle);
      break;
    }
  }
  if (!newVehicle.type) {
    newVehicle.type = types["notCoveredVehicle"].type;
    newVehicle.isInsurable = false;
  }
  return newVehicle;
}
