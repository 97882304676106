import vehicleConstants from "../../../../constants/vehicle.constants";

const { RV_VEHICLE } = vehicleConstants;
const PYRAMID_TYPE_PB = 'PB';
const PYRAMID_TYPE_LB = 'LB';
export {
    PYRAMID_TYPE_LB,
    PYRAMID_TYPE_PB
}

export default {
    type: RV_VEHICLE,
    matchesType: (vehicle) => isLbType(vehicle) || isPbType(vehicle),
    isInsurable: () => true
}

const isLbType = (vehicle) => {
    return vehicle.data.typeCode === PYRAMID_TYPE_LB &&
        vehicle.technicalData.chassi1Code === '123'
};

const isPbType = (vehicle) => {
    return vehicle.data.typeCode === PYRAMID_TYPE_PB &&
        vehicle.technicalData.chassi1Code === '51' &&
        vehicle.technicalData.chassi2Code === '34'
};