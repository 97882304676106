export default {
  GET_USER_INSURANCES_REQUEST: "GET_USER_INSURANCES_REQUEST",
  GET_USER_INSURANCES_SUCCESS: "GET_USER_INSURANCES_SUCCESS",
  GET_USER_INSURANCES_FAILURE: "GET_USER_INSURANCES_FAILURE",
  GET_ALL_INSURANCES_REQUEST: "GET_ALL_INSURANCES_REQUEST",
  GET_ALL_INSURANCES_SUCCESS: "GET_ALL_INSURANCES_SUCCESS",
  GET_ALL_INSURANCES_FAILURE: "GET_ALL_INSURANCES_FAILURE",
  ADD_CO_INSURED_REQUEST: "ADD_CO_INSURED_REQUEST",
  ADD_CO_INSURED_SUCCESS: "ADD_CO_INSURED_SUCCESS",
  ADD_CO_INSURED_FAILURE: "ADD_CO_INSURED_FAILURE",
  SIGN_NEW_INSURANCE_REQUEST: "SIGN_NEW_INSURANCE_REQUEST",
  SIGN_NEW_INSURANCE_SUCCESS: "SIGN_NEW_INSURANCE_SUCCESS",
  SIGN_NEW_INSURANCE_FAILURE: "SIGN_NEW_INSURANCE_FAILURE",
  TOGGLE_INSURANCE_CARD: "TOGGLE_INSURANCE_CARD",
  ORDER_KEY_TABS_REQUEST: "ORDER_KEY_TABS_REQUEST",
  ORDER_KEY_TABS_SUCCESS: "ORDER_KEY_TABS_SUCCESS",
  ORDER_KEY_TABS_FAILURE: "ORDER_KEY_TABS_FAILURE",
  ADD_CO_INSURED_NORWAY_REQUEST: "ADD_CO_INSURED_NORWAY_REQUEST",
  ADD_CO_INSURED_NORWAY_SUCCESS: "ADD_CO_INSURED_NORWAY_SUCCESS",
  ADD_CO_INSURED_NORWAY_FAILURE: "ADD_CO_INSURED_NORWAY_FAILURE",
  CO_INSURANCE_PRICE_LOOKUP_REQUEST: "CO_INSURANCE_PRICE_LOOKUP_REQUEST",
  CO_INSURANCE_PRICE_LOOKUP_SUCCESS: "CO_INSURANCE_PRICE_LOOKUP_SUCCESS",
  CO_INSURANCE_PRICE_LOOKUP_FAILURE: "CO_INSURANCE_PRICE_LOOKUP_FAILURE"
};
