import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hamburgerSelectors } from '../../selectors';
import { hamburgerActions } from '../../actions';
import Sidebar from './Sidebar';
import HamburgerMenuIcon from './HamburgerMenuIcon';
import Logout from './Logout';

const HIDE_HAMBURGER_WIDTH = 1200;

class HamburgerMenuContent extends PureComponent {
  componentDidMount() {
    const { closeHamburger } = this.props;
    window.addEventListener(
      'resize',
      () => {
        if (window.innerWidth <= HIDE_HAMBURGER_WIDTH) return;
        closeHamburger();
      },
      false
    );
  }

  componentDidUpdate() {
    const { isOpen } = this.props;
    isOpen
      ? document.body.classList.add('hamburger-open')
      : document.body.classList.remove('hamburger-open');
  }

  render() {
    const { isOpen, closeHamburger } = this.props;
    return (
      <div className={classNames('hamburger-content', { open: isOpen })}>
        <div className="hamburger-inner-content fixed">
          <div className="flex column content-wrapper">
            <HamburgerMenuIcon
              className={classNames({ 'show-menu': isOpen })}
              onClick={closeHamburger}
            />
            <Sidebar className="hamburger-nav" onClick={closeHamburger} />
            <hr className="gold-divider" />
            <Logout {...this.props} onClick={closeHamburger} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isOpen: hamburgerSelectors.isOpen(state)
});

const mapDispatchToProps = dispatch => ({
  closeHamburger: () => dispatch(hamburgerActions.closeHamburger())
});

HamburgerMenuContent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeHamburger: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HamburgerMenuContent);
