import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Accept, Cancel } from "sign-insurance-components";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import InformationBanner from "./components/banner/InformationBanner";
import FooterContainer from "./components/footer/FooterContainer";
import PrivateRoute from "./components/helper/PrivateRoute";
import StaticLayout from "./components/helper/StaticLayout";
import ModalContainer from "./components/modal/ModalContainer";
import Sidebar from "./components/navbar/Sidebar";
import TopHeader from "./components/navbar/TopHeader";
import { routesConstants } from "./constants";
import Benefits from "./Views/Benefits";
import CustomerService from "./Views/CustomerService";
import Details from "./Views/Details";
import HomePage from "./Views/HomePage";
import InsurancePage from "./Views/Insurances";
import InvoicePage from "./Views/InvoicePage";
import LoginPage from "./Views/LoginPage";
import ShopPage from "./Views/ShopPage";
import SnackbarComponent from "./components/snackbar/SnackbarComponent";
import HamburgerMenuContent from "./components/navbar/HamburgerMenuContent";
import Overlay from "./components/overlay/Overlay";
import VerticalPromotionalBanner from "./components/banner/VerticalPromotionalBanner";
import { themes } from "./helpers/config";

// eslint-disable-next-line no-underscore-dangle
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const {
  HOME,
  LOGIN,
  INSURANCES,
  DETAILS,
  CUSTOMER_SERVICE,
  INVOICES,
  BENEFITS,
  SHOP,
  SHOPACCEPT,
  SHOPCANCEL,
  SHOPACCEPTCARD,
  SHOPACCEPTDIRECTDEBIT
} = routesConstants;

const App = ({ history }) => (
  <div className="body content">
    <StaticLayout component={TopHeader} history={history} isTopHeader />
    <div className="container flex main row justify-around">
      <StaticLayout
        component={Sidebar}
        history={history}
        className="side-bar"
      />
      <main
        id="main"
        className={classNames("container", "main-content", {
          "zero-padding": history.location.pathname === CUSTOMER_SERVICE,
          "zero-padding-grey": history.location.pathname === SHOP
        })}
      >
        <Switch>
          <PrivateRoute exact path={HOME} component={HomePage} />
          <PrivateRoute exact path={INSURANCES} component={InsurancePage} />
          <PrivateRoute exact path={DETAILS} component={Details} />
          <PrivateRoute
            exact
            path={CUSTOMER_SERVICE}
            component={CustomerService}
          />
          <PrivateRoute exact path={INVOICES} component={InvoicePage} />
          {!themes.isSbf() && <PrivateRoute exact path={BENEFITS} component={Benefits} />}
          {themes.isAssistans() && <PrivateRoute exact path={SHOP} component={ShopPage} />}
          <PrivateRoute exact path={SHOPACCEPT} component={Accept} />
          <PrivateRoute exact path={SHOPCANCEL} component={Cancel} />
          <PrivateRoute exact path={SHOPACCEPTDIRECTDEBIT} component={Accept} />
          <PrivateRoute exact path={SHOPACCEPTCARD} component={Accept} />
          <Route path={LOGIN} component={LoginPage} />
          {/* IMPORTANT: This route has to be the last one */}
          <Route render={() => <Redirect to={LOGIN} />} />
        </Switch>
      </main>
      <StaticLayout
        component={VerticalPromotionalBanner}
        className="vertical-promotional-banner"
        history={history}
      />
    </div>
    <StaticLayout component={FooterContainer} history={history} />
    <ModalContainer />
    <SnackbarComponent />
    <StaticLayout
      component={InformationBanner}
      className="information-banner information-snackbar"
      history={history}
    />
    <HamburgerMenuContent history={history} />
    <Overlay />
  </div>
);

App.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }).isRequired
};

export default withRouter(App);
