import { vehicleConstants } from "../../../../../constants";

const {
    CARAVAN_VEHICLE,
    PERSONAL_VEHICLE
} = vehicleConstants;

export default {
    types: [
        'HVB',
    ],
    coverage: [
        CARAVAN_VEHICLE,
        PERSONAL_VEHICLE
    ],
    isCoInsurable: true
}
