import React from "react";
import Form from "../../helper/Form";
import Input from "../../helper/Input";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { validation } from "../../../helpers";
import lang from "../../../helpers/lang-constants";
import { modalSelectors } from "../../../selectors";
import { createTextMask } from "redux-form-input-masks";

const language = lang.customer.components.modal.co_insured_modal.form;
const { required, validSSN } = validation;

const maskLastFourDigits = formHasInitialValue => {
  if (formHasInitialValue) {
    return createTextMask({
      pattern: "999999****",
      allowEmpty: true,
      guide: false
    });
  }
  return {};
};

let CoInsuredFormSe = ({ initialValues }) => {
  const formHasInitialValue = initialValues.socialNumber ? true : false;
  return (
    <Form
      className="co-insurance-component co-insurance-se"
      handleSubmit={() => {}}
      text="Add coinsured"
      showButton={false}
      isRow={false}
    >
      <Field
        id="socialNumber"
        label={language.customer_code.text}
        name="socialNumber"
        component={Input}
        type="tel"
        placeholder={language.customer_code.placeholder}
        validate={[required, validSSN]}
        {...maskLastFourDigits(formHasInitialValue)}
        disabled={formHasInitialValue}
      />
    </Form>
  );
};

const mapStateToProps = state => ({
  initialValues: {
    socialNumber: modalSelectors.getFamilyMember(state, "")
      ? modalSelectors.getFamilyMember(state, "").socialNumber
      : ""
  }
});

const mapDispatchToProps = dispatch => ({});

CoInsuredFormSe = reduxForm(
  {
    form: "coInsuredSe",
    enableReinitialize: true
  },
  mapStateToProps
)(CoInsuredFormSe);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoInsuredFormSe);
