import lang from "./lang-constants";
import { themes } from "../helpers/config";
const language = lang.customer.helpers.validation;

const required = value => (value ? undefined : language.mandatory_field);
const validSSN = value =>
  value && !/^([0-9]){10,12}$/.test(value)
    ? language.invalid_social_number
    : undefined;
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? language.invalid_email
    : undefined;
const number = value => {
  if (themes.isAssistans()) {
    return value &&
      !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value)
      ? language.invalid_phone_number
      : undefined;
  } else {
    return value && !/^([0-9+]{4,14})$/im.test(value)
      ? language.invalid_phone_number
      : undefined;
  }
};

const normalizeSSN = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  return onlyNums.substring(0, 12);
};
const validBornDate = value =>
  value && !/[0-9]{8}/g.test(value)
    ? language.invalid_num_characters
    : undefined;

const maxLength = max => value =>
  value && value.length > max ? language.invalid_num_characters : undefined;

export default {
  normalizeSSN,
  required,
  validSSN,
  email,
  number,
  validBornDate,
  maxLength
};
