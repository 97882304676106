import Bowser from "bowser";
import BOWSER_ALIASES from "bowser/src/constants";

const browser = Bowser.getParser(window.navigator.userAgent);
const browsers = BOWSER_ALIASES.BROWSER_ALIASES_MAP;

export function isSafari() {
  return browser.isBrowser(browsers.Safari);
}

export function isChrome() {
  return browser.isBrowser(browsers.Chrome);
}

export function isFirefox() {
  return browser.isBrowser(browsers.Firefox);
}
