import { fromJS } from "immutable";
import { userConstants, bankidConstants, smsConstants } from "../constants";
import { updateMap, getUser } from "./functions";

const { USER_LOGOUT } = userConstants;
const {
  AUTHENTICATE_REQUEST,
  AUTHENTICATE_FAILURE,
  COLLECT_FAILURE,
  LOGIN_SUCCESS,
  OPEN_APP_PENDING,
  OPEN_APP_FAILURE,
  OPEN_APP_SUCCESS
} = bankidConstants;

const {
  SMS_TOKEN_REQUEST,
  SMS_TOKEN_SUCCESS,
  SMS_TOKEN_FAILURE,
  SMS_AUTHENTICATE_REQUEST,
  SMS_AUTHENTICATE_SUCCESS,
  SMS_AUTHENTICATE_FAILURE
} = smsConstants;

const initialState = fromJS({
  loggedIn: getUser(),
  user: getUser() || {},
  loading: {
    bankIdAuth: false,
    requestToken: false,
    smsAuth: false
  },
  ssn: "",
  sentSmsToken: false,
  isAuthError: false,
  openBankIdPending: false,
  autoStartToken: null
});

const authentication = (state = initialState, action) => {
  const { type, error, ssn } = action;
  let newState = state;
  switch (type) {
    case AUTHENTICATE_REQUEST:
      newState = newState.set("isAuthError", false);
      newState = updateMap(newState, "loading", "bankIdAuth", true);
      return newState;
    case SMS_TOKEN_REQUEST:
      newState = newState.set("isAuthError", false);
      newState = newState.set("ssn", ssn);
      newState = updateMap(newState, "loading", "requestToken", true);
      newState = newState.set("sentSmsToken", false);
      return newState;
    case SMS_AUTHENTICATE_REQUEST:
      newState = newState.set("isAuthError", false);
      newState = updateMap(newState, "loading", "smsAuth", true);
      return newState;

    case SMS_TOKEN_SUCCESS:
      newState = updateMap(newState, "loading", "requestToken", false);
      newState = newState.set("sentSmsToken", true);
      return newState;
    case SMS_AUTHENTICATE_SUCCESS:
    case LOGIN_SUCCESS:
      newState = newState.set("loggedIn", true);
      newState = newState.set("user", fromJS(getUser()));
      newState = newState.set("loading", initialState.get("loading"));
      return newState;

    case AUTHENTICATE_FAILURE:
    case COLLECT_FAILURE:
    case SMS_AUTHENTICATE_FAILURE:
    case SMS_TOKEN_FAILURE:
      newState = newState.set("isAuthError", true);
      newState = newState.set("loggedIn", false);
      newState = newState.set("user", initialState.get("user"));
      newState = newState.set("loading", initialState.get("loading"));
      return newState;
    case OPEN_APP_PENDING:
      newState = newState.set("openBankIdPending", true);
      newState = newState.set("autoStartToken", initialState.get("autoStartToken"))   
      return newState;
    case OPEN_APP_SUCCESS:
      newState = newState.set("openBankIdPending", initialState.get("openBankIdPending"));
      newState = newState.set("autoStartToken", action.autoStartToken)
      return newState;
    case OPEN_APP_FAILURE:
      newState = newState.set("openBankIdPending", initialState.get("openBankIdPending"));
      newState = newState.set("autoStartToken", initialState.get("autoStartToken"))   
      return newState;
    default:
      return newState;
  }
};

export default authentication;
