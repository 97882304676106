import transformVehicle from "./vehicle";

export default function handleVehicleResponse(apiResponse) {
  if (!Array.isArray(apiResponse.vehicles)) {
    return apiResponse;
  }
  apiResponse.vehicles = apiResponse.vehicles.map(vehicle =>
    transformVehicle(vehicle)
  );
  return apiResponse;
}
