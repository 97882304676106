import { vehicleConstants } from "../../../../../constants";

const {
    MOTORCYCLE
} = vehicleConstants;

export default {
    types: [
        'M01',
        'M02',
        'MS'
    ],
    coverage: [
        MOTORCYCLE
    ],
    isCoInsurable: true,
    recommendationTitle: "Vägassistans MC",
    recommendationText: "Teckna vår Vägassistans MC så får du ett personligt försäkringsskydd vilket betyder att du får hjälp oavsett motorcykel som du kör. Genom att kombinera flera försäkringar hos oss får du som kund 30% rabatt på den totala försäkringspremien."
}
