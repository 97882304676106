import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { destroy } from "redux-form";
import { modalActions } from "../../actions";
import { routesConstants } from "../../constants";
import { insuranceSelectors } from "../../selectors";
import Button from "../helper/Button";
import lang from "../../helpers/lang-constants";

const { INSURANCES } = routesConstants;
const { getUserInsurance, getInsuranceIcon } = insuranceSelectors;
const language = lang.customer.components.insurance.insurance_card_simple;

const InsuranceCardSimple = ({ userInsurance, openModal }) => {
  const list = userInsurance.valueSeq().map((insurance, i) => {
    const isKeyTabInsurance = insurance
      .get("insuranceNumber")
      .toLowerCase()
      .startsWith("b");
    const isCoInsurable = insurance.get("isCoInsurable");
    const showButton = isCoInsurable || isKeyTabInsurance;
    return (
      <div key={insurance.get("articleType", i)}>
        {insurance.get("isActive", false) && (
          <div className="flex align-center justify-between insurance-card-simple">
            <Link
              to={INSURANCES}
              className="insurance-name flex row align-center insurance-description flex-grow"
            >
              {getInsuranceIcon(insurance.get("articleType")) && (
                <i
                  className={`${getInsuranceIcon(
                    insurance.get("articleType")
                  )} insurance-icon`}
                />
              )}
              <h4>{insurance.get("productName")}</h4>
              <i className="fal fa-chevron-right only-mobile chevron" />
            </Link>
            {showButton &&
              <Button
                type="button"
                btnType="primary"
                onClick={() =>
                  openModal(
                    isKeyTabInsurance ? "orderKeyTabs" : "coInsured",
                    insurance.get("articleCode", 0)
                  )
                }
              >
                {isKeyTabInsurance
                  ? language.button.order_key_ring_text
                  : language.button.add_co_insurance_text}
              </Button>
            }
          </div>
        )}
      </div>
    );
  });
  return <div>{Boolean(list.size) && <div>{list}</div>}</div>;
};

const mapStateToProps = (state, { id }) => ({
  userInsurance: getUserInsurance(state, id)
});

const mapDispatchToProps = (dispatch, { id }) => ({
  openModal: (modal, articleCode) => {
    dispatch(modalActions.openModal(modal, articleCode, id));
    dispatch(destroy("getUserForm"));
  }
});

InsuranceCardSimple.propTypes = {
  openModal: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsuranceCardSimple);
