import { authHeader, history } from "../helpers";
import { apiConstants, routesConstants } from "../constants";
import handleVehicleResponse from "./handlers/vehicle/vehicleHandler";

const { LOGIN } = routesConstants;

function handleResponse(response) {
  if (process.env.NODE_ENV === "development") console.log("response", response);
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (response.ok) return data;
    if (response.status === 401) { 
      logout();
      return Promise.reject({
        unauthorized: true
      });
    }
    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  });
}

function logout() {
  const requestOptions = {
    method: "POST",
    headers: authHeader()
  };
  fetch(apiConstants.API.USER.LOGOUT, requestOptions).then(data => data);
  localStorage.removeItem("user");
  history.push(LOGIN);
}

const updateUser = body => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body)
  };
  return fetch(apiConstants.API.USER.ME, requestOptions)
    .then(handleResponse)
    .then(data => data);
};

const getUserData = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(apiConstants.API.USER.ME, requestOptions)
    .then(handleResponse)
    .then(data => data);
};

const fetchUserFellowApplicants = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(apiConstants.API.USER.FELLOW_APPLICANTS, requestOptions)
    .then(handleResponse)
    .then(data => data);
};

const getUserVehicles = socialNumber =>
  fetch(apiConstants.API.USER.LOOKUP_VEHICLE(socialNumber))
    .then(handleResponse)
    .then(data => handleVehicleResponse(data));

export default {
  logout,
  getUserData,
  updateUser,
  getUserVehicles,
  fetchUserFellowApplicants
};
