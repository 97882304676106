import { Map, Set, List } from "immutable";
import { createSelector } from "reselect";

const sortDates = array => {
  const MONTH = 0;
  const YEAR = 1;
  return array.sort((a, b) => {
    if (!a.get("date") || !b.get("date")) return Map();
    const first = a.get("date", "").split("/");
    const next = b.get("date", "").split("/");
    if (first[YEAR] > next[YEAR]) return -1;
    if (first[YEAR] < next[YEAR]) return 1;
    if (first[MONTH] > next[MONTH]) return -1;
    if (first[MONTH] < next[MONTH]) return 1;
    return 0;
  });
};

const getMagazine = ({ magazines }, id) =>
  magazines.getIn(["items", id], Map());
const getMagazineIds = ({ magazines }) => magazines.get("itemIds", List());
const getLoading = ({ magazines }) => magazines.get("loading", false);

const getMagazineIdsState = () =>
  createSelector(
    [getMagazineIds],
    ids => {
      const sortedIds = sortDates(ids);
      if (!sortedIds.size) return Set();
      if (sortedIds.size >= 2) return sortedIds.slice(0, 2);
      return sortedIds.get(0);
    }
  );

export default {
  getMagazineIdsState,
  getLoading,
  getMagazine
};
