const getOpen = ({ modal }) => modal.get("open", false);
const getComponent = ({ modal }) => modal.get("component", "");
const getInsuranceId = ({ modal }) => modal.get("insuranceId", "");
const getArticleCode = ({ modal }) => modal.get("articleCode", "");
const getAutoStartToken = ({ modal }) => modal.get("autoStartToken", null);
const getOrderRef = ({ modal }) => modal.get("orderRef", null);
const getFamilyMember = ({ modal }) => modal.get("familyMember", null);

export default {
  getOpen,
  getComponent,
  getInsuranceId,
  getArticleCode,
  getAutoStartToken,
  getOrderRef,
  getFamilyMember
};
