import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { insuranceActions, userActions, familyActions } from "../../actions";
import { insuranceSelectors } from "../../selectors";
import ListComponent from "../helper/ListComponent";
import LoadingSpinner from "../helper/LoadingSpinner";
import Heading from "../helper/Heading";
import { is } from "immutable";
import lang from "../../helpers/lang-constants";

const { getUpdating, getItemIds, hasOnlyKeyTabInsurance } = insuranceSelectors;
const language = lang.customer.components.insurance.insurance;

class Insurances extends Component {
  componentDidMount() {
    const {
      fetchInsurances,
      fetchUserData,
      fetchFamilyInHouseHold
    } = this.props;
    fetchInsurances();
    fetchUserData();
    fetchFamilyInHouseHold();
  }

  render() {
    const {
      isLoading,
      itemsIds,
      component: Component,
      simpleCard = false,
      isOnlyKeyTabs
    } = this.props;

    return (
      <div
        className={classNames("container insurances", {
          "insurance-card-simple-full-width": simpleCard
        })}
      >
        {itemsIds.size === 0 && isLoading && (
          <div>
            <Heading
              heading={language.heading_text}
              isPrimary={Boolean(false)}
            />
            <LoadingSpinner isLoading={isLoading} size={25} />
          </div>
        )}
        {itemsIds.size > 0 && !isOnlyKeyTabs && (
          <ListComponent
            ids={itemsIds}
            component={Component}
            direction="column"
            isPrimaryHeading={false}
            heading={language.heading_text}
            isLoading={isLoading}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: getUpdating(state, "fetchUserInsurances"),
  itemsIds: getItemIds(state),
  isOnlyKeyTabs: hasOnlyKeyTabInsurance(state)
});

const mapDispatchToProps = dispatch => ({
  fetchInsurances: () => dispatch(insuranceActions.getInsurances()),
  fetchFamilyInHouseHold: () =>
    dispatch(familyActions.fetchFamilyInHouseHold()),
  fetchUserData: () => dispatch(userActions.getUserData())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Insurances);
