import authSelectors from "./authentication.selector";
import insuranceSelectors from "./insurances.selector";
import userSelectors from "./user.selector";
import benefitSelectors from "./benefits.selector";
import magazineSelectors from "./magazines.selector";
import invoiceSelectors from "./invoices.selector";
import modalSelectors from "./modal.selector";
import bannerSelectors from "./banner.selector";
import footerSelectors from "./footer.selector";
import snackbarSelectors from "./snackbar.selector";
import hamburgerSelectors from "./hamburger.selector";
import familySelectors from "./family.selector";

export {
  authSelectors,
  insuranceSelectors,
  userSelectors,
  benefitSelectors,
  magazineSelectors,
  invoiceSelectors,
  modalSelectors,
  bannerSelectors,
  footerSelectors,
  snackbarSelectors,
  hamburgerSelectors,
  familySelectors
};
