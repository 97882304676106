import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { hamburgerSelectors } from '../../selectors';

const { isOpen } = hamburgerSelectors;

const Overlay = ({ isMenuOpen }) => (
  <div
    className={classNames('overlay absolute', { 'show-overlay': isMenuOpen })}
  />
);

const mapStateToProps = state => ({
  isMenuOpen: isOpen(state)
});

Overlay.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(Overlay);
