import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '../helper/Button';
import Input from '../helper/Input';
import LoadingSpinner from '../helper/LoadingSpinner';
import { familyActions } from '../../actions';
import { familySelectors } from '../../selectors';
import { validation } from '../../helpers';
import { modalConstants } from '../../constants';
import lang from "../../helpers/lang-constants";
import BaseModal from './BaseModal';

const { MODAL_NOT_FOCUSED } = modalConstants;
const { required, number } = validation;
const { getUpdating } = familySelectors;
const language = lang.customer.components.modal.invite_friend_modal;

class InviteFriendModal extends BaseModal {
  render() {
    const { addFriend, isLoading, closeModal } = this.props;
    return(
      <div ref={this.targetRef} className="modal content" tabIndex={MODAL_NOT_FOCUSED}>
        <h4 className="dialog-title flex row justify-between">
          {language.title}
          <i
            className="fal fa-times-circle modal-close color-gold"
            onClick={closeModal}
            onKeyPress={closeModal}
            tabIndex={0}
            role="button"
          />
        </h4>
        <div className="dialog-content">
          <span className="content-description dialog-form">
            {language.description}
          </span>
          <Field
            name="mobilePhone"
            component={Input}
            type="tel"
            placeholder={language.submit_placeholder}
            validate={[required, number]}
          />
        </div>
        <div className="dialog-actions flex justify-center">
          <Button type="submit" onClick={addFriend} btnType="primary">
            <LoadingSpinner text={language.submit_button.text} isLoading={isLoading} />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: getUpdating(state, 'addFriend')
});

const mapDispatchToProps = dispatch => ({
  addFriend: () => dispatch(familyActions.addFriend())
});

export default reduxForm({
  form: 'inviteFriendForm'
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InviteFriendModal)
);
