import { fromJS, Set } from "immutable";
import { magazinesConstants } from "../constants";
import { updateMap } from "./functions";

const {
  GET_MAGAZINES_REQUEST,
  GET_MAGAZINES_SUCCESS,
  GET_MAGAZINES_FAILURE
} = magazinesConstants;

const initialState = fromJS({
  loading: false,
  items: {},
  itemIds: {},
  error: ""
});

const magazines = (state = initialState, action) => {
  const { error, type } = action;
  let newState = state;
  switch (type) {
    case GET_MAGAZINES_REQUEST:
      newState = newState.set("loading", true);
      newState = newState.set("error", "");
      return newState;
    case GET_MAGAZINES_SUCCESS: {
      newState = newState.set("loading", false);
      if (!Array.isArray(action.magazines)) return newState;
      const defaultDate = `${new Date().getMonth() +
        1}/${new Date().getFullYear()}`;
      action.magazines.forEach(magazine => {
        const {
          id,
          acf: { link },
          title,
          acf: { publish_date },
          _embedded: { "wp:featuredmedia": media }
        } = magazine;
        newState = updateMap(newState, "itemIds", id, {
          id,
          date: publish_date || defaultDate
        });
        newState = updateMap(newState, "items", id, {
          id,
          link,
          title,
          date: publish_date || defaultDate,
          image: media[0].source_url
        });
      });
      return newState;
    }
    case GET_MAGAZINES_FAILURE:
      newState = newState.set("loading", true);
      newState = newState.set("error", error);
      return newState;
    default:
      return newState;
  }
};

export default magazines;
