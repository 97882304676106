import { fromJS } from "immutable";
import { snackbarConstants } from "../constants";
import { updateMap } from "./functions";

const { OPEN_SNACKBAR, CLOSE_SNACKBAR } = snackbarConstants;

const initialState = fromJS({
  isOpen: false,
  snackbarType: { failure: false, success: false },
  title: "",
  message: ""
});

const snackbar = (state = initialState, action) => {
  const { type, snackbarType, title, message } = action;
  let newState = state;
  switch (type) {
    case OPEN_SNACKBAR:
      newState = newState.set("snackbarType", initialState.get("snackbarType"));
      newState = updateMap(newState, "snackbarType", snackbarType, true);
      newState = newState.set("isOpen", true);
      newState = newState.set("title", title);
      newState = newState.set("message", message);
      return newState;
    case CLOSE_SNACKBAR:
      newState = newState.set("isOpen", false);
      return newState;
    default:
      return newState;
  }
};

export default snackbar;
