import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    background: "white",
  },
};

const SelectComponent = ({ input, classes, options }) => (
  <div className="input select">
    <Select {...input} displayEmpty className={classNames(classes.root)}>
      {options.map(({ value, name }) => (
        <MenuItem key={value} value={value}>
          {name}
        </MenuItem>
      ))}
    </Select>
  </div>
);

SelectComponent.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default withStyles(styles)(SelectComponent);
