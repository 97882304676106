import lang from "../helpers/lang-constants";

const language = lang.customer.components.constants.error_constants;

export default {
  GENERIC_ERROR_MESSAGE: language.unknown_error_text,
  SESSION_ERROR_MESSAGE: language.session_error,
  SUSPENDED_ERROR_MESSAGE: language.customer_suspended,
  ADD_CO_INSURED_ERROR_MESSAGE: language.add_co_insured_error
};
