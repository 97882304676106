import { Map } from "immutable";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import { footerActions } from "../../actions";
import { footerSelectors } from "../../selectors";
import Footer from "./Footer";
import SnackLogo from "./SnackLogo";
import FooterCompanyInfo from "./FooterCompanyInfo";
import FooterSocialMedia from "./FooterSocialMedia";
import FooterDivider from "./FooterDivider";
import FooterWave from "./FooterWave";

const { getFooterFields } = footerSelectors;

class FooterContainer extends PureComponent {
  componentDidMount() {
    const { fetchFields } = this.props;
    fetchFields();
  }

  render() {
    const { fields } = this.props;
    return (
      <div>
        
        <footer>
          <FooterWave />
          {/* <SnackLogo fields={fields} /> */}
          <Footer fields={fields}>
            <FooterDivider />
            <FooterCompanyInfo fields={fields} />
            <FooterDivider />
            <FooterSocialMedia fields={fields} />
            <FooterDivider />
          </Footer>
        </footer>
      </div>
    );
  }
}

FooterContainer.defaultProps = {
  fields: Map()
};

FooterContainer.propTypes = {
  fetchFields: PropTypes.func.isRequired,
  fields: ImmutablePropTypes.mapContains({
    app_android_link: PropTypes.string,
    app_button_text: PropTypes.string,
    app_image_small: PropTypes.string,
    app_ios_link: PropTypes.string,
    campaign_category_text: PropTypes.shape({
      campaign_header_category: PropTypes.number,
      campaign_header: PropTypes.string
    }),

    footer_app: PropTypes.string,
    footer_heading: PropTypes.string,
    footer_icon_1: PropTypes.string,
    footer_icon_1_text: PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string
    }),
    footer_icon_2: PropTypes.string,
    menu_company: PropTypes.string,
    menu_company_url: PropTypes.string,
    menu_name: PropTypes.string,
    menu_private: PropTypes.string,
    menu_private_url: PropTypes.string,
    my_pages: PropTypes.string,
    my_pages_url: PropTypes.string,
    phone_number: PropTypes.string,
    roadside_assistance: PropTypes.string,
    roadside_assistance_url: PropTypes.string,
    social_media_links: PropTypes.shape({
      icon: PropTypes.string,
      link: PropTypes.string
    }),
    sub_headings: PropTypes.shape({
      sub_heading: PropTypes.string,
      sub_texts: PropTypes.arrayOf(
        PropTypes.shape({
          sub_texts: PropTypes.string
        })
      )
    }),
    subscribe_link: PropTypes.object
  })
};

const mapStateToProps = state => ({
  fields: getFooterFields(state)
});

const mapDispatchToProps = dispatch => ({
  fetchFields: () => dispatch(footerActions.fetchFooterFields())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterContainer);
