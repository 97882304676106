import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import path from "../../assets/images/path.svg";
import lang from "../../helpers/lang-constants";

const language = lang.customer.components.helper.read_more_card;

const ReadMoreCard = ({ text, url, newLink = false, ...rest }) => (
  <li
    className={classNames(
      "read-more-card flex column align-start justify-start",
      rest.className
    )}
  >
    {newLink ? (
      <a
        className="flex justify-center align-center read-more-link"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={path} alt={language.img_alt} />
      </a>
    ) : (
      <Link to={url} className="flex justify-center align-center">
        <img src={path} alt={language.img_alt} />
      </Link>
    )}
    <span className="read-more-link">{text}</span>
  </li>
);

ReadMoreCard.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  newLink: PropTypes.bool
};

export default ReadMoreCard;
