import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { insuranceSelectors } from "../../selectors";
import ListComponent from "../helper/ListComponent";
import SummationCard from "./SummationCard";
import lang from "../../helpers/lang-constants";

const { getItemIdGroup } = insuranceSelectors;
const language = lang.customer.components.insurance.summations;

const Summations = ({ itemsIds }) => (
  <div>
    {itemsIds.size > 0 && (
      <div className={classNames("container")}>
        <ListComponent
          ids={itemsIds.slice(0, 1).toList()}
          component={SummationCard}
          direction="column"
          heading={language.title_text}
          isPrimaryHeading={false}
          isLoading={!itemsIds.size}
        />
      </div>
    )}
  </div>
);

const mapStateToProps = state => ({
  itemsIds: getItemIdGroup(state)
});

export default connect(mapStateToProps)(Summations);
