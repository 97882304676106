import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import { bannerActions } from "../../actions";
import { bannerSelectors } from "../../selectors";

const { getLoading, getBanner } = bannerSelectors;

class HorizontalPromotionalBanner extends PureComponent {
  componentDidMount() {
    const { fetchBanner } = this.props;
    fetchBanner();
  }

  render() {
    const { isLoading, banner, ...rest } = this.props;
    const image = banner.get("backgroundImage", "");
    const linkUrl = banner.getIn(["link", "url"], "");
    const linkTitle = banner.getIn(["link", "title"], "");
    const isActive = banner.get("active", "");
    const displayBanner = isActive && image && linkUrl;
    return displayBanner ? (
      <div className={classNames(rest.className || "")}>
        <div className="container">
          <a href={linkUrl}>
            <img src={image} alt={linkTitle} />
          </a>
        </div>
      </div>
    ) : null;
  }
}

HorizontalPromotionalBanner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fetchBanner: PropTypes.func.isRequired,
  banner: ImmutablePropTypes.mapContains({
    active: PropTypes.bool,
    backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    link: PropTypes.object
  }).isRequired
};

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  banner: getBanner(state, "horizontalPromotionalBanner")
});

const mapDispatchToProps = dispatch => ({
  fetchBanner: () => dispatch(bannerActions.fetchBanners())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HorizontalPromotionalBanner);
