import React from "react";
import { Field, reduxForm } from "redux-form";
import classNames from "classnames";
import Form from "../helper/Form";
import Input from "../helper/Input";
import { validation } from "../../helpers";
import BaseModal from "./BaseModal";

const { required } = validation;

class DebitCardModal extends BaseModal { 
  render() {
    return (
      <Form>
        <div ref={this.targetRef} className={classNames("modal", "content")}>
          <Field name="name" component={Input} type="text" validate={required} />
          <Field
            name="cardNumber"
            component={Input}
            type="text"
            validate={required}
          />
          <Field name="cvv" component={Input} type="text" validate={required} />
          <Field name="date" component={Input} type="text" validate={required} />
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: "changeDebitCard"
})(DebitCardModal);
