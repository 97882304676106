import { fromJS } from "immutable";
import { footerConstants } from "../constants";

const {
  GET_FOOTER_FIELDS_REQUEST,
  GET_FOOTER_FIELDS_SUCCESS,
  GET_FOOTER_FIELDS_FAILURE
} = footerConstants;

const initialState = fromJS({
  loading: false,
  fields: {},
  error: ""
});

const footer = (state = initialState, action) => {
  const { type, error, data } = action;
  let newState = state;
  switch (type) {
    case GET_FOOTER_FIELDS_REQUEST:
      return newState.set("loading", true);
    case GET_FOOTER_FIELDS_SUCCESS:
      newState = newState.set("loading", false);
      newState = newState.set("fields", fromJS(data.acf));
      return newState;
    case GET_FOOTER_FIELDS_FAILURE:
      newState = newState.set("loading", false);
      newState = newState.set("error", error);
      return newState;
    default:
      return newState;
  }
};

export default footer;
