import BaseAnalytics from "../base_analytics.skeleton";
import { env } from "../../../helpers/config";

const analytics = new BaseAnalytics();

if (env.isProduction()) {
  analytics.TAG = "UA-54526145-12";
}

export default analytics;
