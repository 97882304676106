import React from "react";
import { connect } from "react-redux";
import { 
  bankIdActions,
  modalActions
} from "../../../actions";
import { authSelectors } from "../../../selectors";
import { device, history } from "../../../helpers";
import { openDeviceBankId } from "../../../helpers/bankid";
import { routesConstants } from "../../../constants";
import BankIdLoginForm from "./BankIdLoginForm";
import { isSafari } from "../../../helpers/browser";

const { getAutoStartToken } = authSelectors;
const { BANKID_COLLECT } = routesConstants;
const TIME_OUT = 60000;
class BankIdRedirect extends React.Component {

  componentWillUnmount() {
    const { closeBankIdModal } = this.props;
    closeBankIdModal();
    this.stopRedirectTimer();
  }

  componentDidMount() {
    const { autoStartToken, match, openBankIdModal } = this.props;
    const { orderRef } = match.params;
    openBankIdModal(orderRef);
    if(device.isAndroid()) {
      this.handleAndroidRedirect(autoStartToken, orderRef);
    } else if(device.isIos()) {
      this.handleIos(autoStartToken, orderRef);
    }
    this.startRedirectTimer();
  }

  handleIos = (autoStartToken, orderRef) => {
    if(isSafari()) {
      if(autoStartToken) {
        setTimeout(openDeviceBankId, 500, autoStartToken, orderRef);
      } else {
        this.gotoCollect();
      }
    } else {
      setTimeout(openDeviceBankId, 500, autoStartToken, orderRef);
      this.gotoCollect();
    }
  }

  handleAndroidRedirect = (autoStartToken, orderRef) => {
    openDeviceBankId(autoStartToken, orderRef);
    this.gotoCollect();
  }

  stopRedirectTimer = () => {
    clearTimeout(this.redirectTimer);
  }

  startRedirectTimer = () => {
    this.redirectTimer = setTimeout(() => {
      this.gotoCollect();
    }, TIME_OUT)
  }

  gotoCollect = () => {
    const { orderRef } = this.props.match.params;
    history.push(`${BANKID_COLLECT}/${orderRef}`);
  }

  render = () => ( <BankIdLoginForm /> )
}

const mapStateToProps = state => ({
    autoStartToken: getAutoStartToken(state)
});

const mapDispatchToProps = dispatch => {
  return {
    openBankIdModal: (orderRef) => {
      dispatch(modalActions.openModal("bankId", null, null, orderRef))
    },
    closeBankIdModal: () => {
      dispatch(modalActions.closeModal("bankId"));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankIdRedirect);