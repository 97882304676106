import { vehicleConstants } from "../../../../../constants";

const {
    PERSONAL_VEHICLE,
    WAGON_VEHICLE
} = vehicleConstants;

export default {
    types: [
        'BSL'
    ],
    coverage: [
        PERSONAL_VEHICLE,
        WAGON_VEHICLE
    ],
    isCoInsurable: true
}
