import { vehicleConstants } from "../../../../../constants";

const {
    RV_VEHICLE
} = vehicleConstants;

export default {
    types: [
        'H01',
        'H02',
        'H03',
        'H04'
    ],
    coverage: [
        RV_VEHICLE
    ],
    isCoInsurable: true,
    recommendationTitle: "Vägassistans Husbil",
    recommendationText: "Vår vägassistansförsäkring Husbil gäller personligen för dig när du kör husbilar som är upp till 25 år och har en maxvikt på 10 ton. Genom att kombinera flera försäkringar hos oss får du som kund 30% rabatt på den totala försäkringspremien."
}
