import React from "react";
import { connect } from "react-redux";
import { insuranceSelectors, invoiceSelectors, userSelectors } from "../../selectors";
import BannerDirectDebit from "../banner/BannerDirectDebit";
import RowContainer from "../helper/RowContainer";
import lang from "../../helpers/lang-constants";
import {themes} from "../../helpers/config"

const { getItemGroupState } = insuranceSelectors;
const language = lang.customer.components.insurance.summation_card;
const { getUserData } = userSelectors;

const SummationCard = ({ itemGroup, userData }) => {

  let customerCode = "";
  if (themes.isAssistans()) {
    customerCode = `${itemGroup.first().get("customerCode", "").slice(0, 6)}-${itemGroup.first().get("customerCode", "").slice(6, 10)}`;
  }else {
    customerCode = itemGroup.first().get("customerCode", "");
  }

  return (
    <div className="container summation">
      <RowContainer>
        <span itemType="leftTitle" className="label-bold">
          {language.insured_text}
        </span>
        <span itemType="leftContent" className="content">
          {itemGroup.first().get("subscriberName", "")}
        </span>
        <span itemType="centerTitle" className="label-bold">
          {language.customer_identifier}
        </span>
        <span itemType="centerContent" className="content">
          {themes.isNorway()? userData.get("born", ""):
            customerCode
          }
        </span>
        <span itemType="rightTitle" className="label-bold">
          {language.customer_code}
        </span>
        <span itemType="rightContent" className="content">
          {customerCode}
        </span>
      </RowContainer>
      {/*

        OSÄKER PÅ OM DETTA SKA VARA KVAR.

        <hr className="gold-divider" />
        <RowContainer>
        <span itemType="leftTitle" className="label-bold">
          Försäkringsperiod
        </span>
        <span itemType="leftContent" className="content">
          {`${dateFormat(
        itemGroup.first().get("startDate", ""),
        "yyyy-mm-dd"
          )} till ${dateFormat(
        itemGroup.first().get("endDate", ""),
        "yyyy-mm-dd"
          )}`}
        </span>
        <span itemType="centerTitle" className="label-bold">
          Betalperiod
        </span>
        <span itemType="centerContent" className="content">
          {itemGroup.first().get("paymentIntervalName", "")}
        </span>
        <span itemType="rightTitle" className="label-bold">
          Betalsätt
        </span>
        <span itemType="rightContent" className="content">
          {itemGroup.first().get("paymentMethodName", "")}
        </span>
      </RowContainer> */}
      {!itemGroup
        .first()
        .get("paymentMethodName")
        .toLowerCase()
        .includes("autogiro") && (
          <BannerDirectDebit
            heading={`${itemGroup
            .first()
            .get("subscriberName")}, ${language.banner_direct_debit.heading_text}`}
          />
        )}
    </div>
  )
};

const makeMapStateToProps = () => {
  const getItemGroup = getItemGroupState();
  return (state, { id }) => ({
    itemGroup: getItemGroup(state, id),
    userData: getUserData(state)
  });
};

export default connect(makeMapStateToProps)(SummationCard);
