import { fromJS, Set } from "immutable";
import { insuranceConstants } from "../constants";
import { updateMap, updateDeepNestedMap } from "./functions";

const {
  GET_USER_INSURANCES_REQUEST,
  GET_USER_INSURANCES_SUCCESS,
  GET_USER_INSURANCES_FAILURE,
  GET_ALL_INSURANCES_REQUEST,
  GET_ALL_INSURANCES_SUCCESS,
  GET_ALL_INSURANCES_FAILURE,
  TOGGLE_INSURANCE_CARD,
  ADD_CO_INSURED_REQUEST,
  ADD_CO_INSURED_SUCCESS,
  ADD_CO_INSURED_FAILURE,
  SIGN_NEW_INSURANCE_REQUEST,
  SIGN_NEW_INSURANCE_SUCCESS,
  SIGN_NEW_INSURANCE_FAILURE,
  ORDER_KEY_TABS_REQUEST,
  ORDER_KEY_TABS_SUCCESS,
  ORDER_KEY_TABS_FAILURE,
  CO_INSURANCE_PRICE_LOOKUP_SUCCESS,
  CO_INSURANCE_PRICE_LOOKUP_REQUEST,
  CO_INSURANCE_PRICE_LOOKUP_FAILURE
} = insuranceConstants;

const initialState = fromJS({
  updating: {},
  userInsurances: {},
  userInsuranceIds: Set(),
  userInsuranceIdGroups: {},
  allInsurances: {},
  allInsuranceIds: Set(),
  error: {},
  coInsurancePrice: {}
});

const insurances = (state = initialState, action) => {
  const {
    items,
    insuranceId,
    error,
    type,
    cardId,
    articleCode,
    coInsurancePriceData
  } = action;
  let newState = state;
  switch (type) {
    case TOGGLE_INSURANCE_CARD: {
      const isOpen = newState.getIn(["allInsurances", cardId, "open"], false);
      newState = updateDeepNestedMap(
        newState,
        "allInsurances",
        cardId,
        "open",
        !isOpen
      );
      return newState;
    }
    case ADD_CO_INSURED_REQUEST:
      return updateDeepNestedMap(
        newState,
        "updating",
        insuranceId,
        articleCode,
        true
      );
    case ORDER_KEY_TABS_REQUEST:
      return updateMap(newState, "updating", "keyTab", true);
    case SIGN_NEW_INSURANCE_REQUEST:
      return updateMap(newState, "updating", "signNewInsurance", true);
    case GET_USER_INSURANCES_REQUEST:
      newState = updateMap(newState, "updating", "fetchUserInsurances", true);
      newState = updateMap(newState, "error", "getUserInsurances", "");
      return newState;
    case GET_ALL_INSURANCES_REQUEST:
      newState = updateMap(newState, "updating", "fetchShopInsurances", true);
      newState = updateMap(newState, "error", "getShopInsurances", "");
      return newState;
    case GET_ALL_INSURANCES_SUCCESS:
      newState = updateMap(newState, "updating", "fetchShopInsurances", false);
      items.forEach(item => {
        const { acf } = item;
        newState = newState.update("allInsuranceIds", idList =>
          idList.add(acf.article_code)
        );
        newState = updateMap(newState, "allInsurances", acf.article_code, acf);
      });
      return newState;
    case GET_USER_INSURANCES_SUCCESS:
      newState = updateMap(newState, "updating", "fetchUserInsurances", false);
      action.insurances.forEach(insurance => {
        const { insuranceNumber, articleCode, isActive } = insurance;
        if (!isActive) return;
        newState = updateDeepNestedMap(
          newState,
          "userInsuranceIdGroups",
          insuranceNumber,
          articleCode,
          articleCode
        );
        newState = newState.update("userInsuranceIds", idList =>
          idList.add(`${insuranceNumber}`)
        );

        newState = updateDeepNestedMap(
          newState,
          "userInsurances",
          insuranceNumber,
          articleCode,
          insurance
        );
      });
      return newState;
    case ADD_CO_INSURED_FAILURE:
      return newState.deleteIn(["updating", insuranceId, articleCode]);
    case SIGN_NEW_INSURANCE_FAILURE:
      newState = updateMap(newState, "updating", "signNewInsurance", false);
      newState = updateMap(newState, "error", "signNewInsurance", error);
      return newState;
    case ORDER_KEY_TABS_FAILURE:
      return updateMap(newState, "updating", "keyTabs", false);
    case GET_USER_INSURANCES_FAILURE:
      newState = updateMap(newState, "updating", "fetchUserInsurances", false);
      newState = updateMap(newState, "error", "getUserInsurance", error);
      return newState;
    case GET_ALL_INSURANCES_FAILURE:
      newState = updateMap(newState, "updating", "fetchShopInsurances", false);
      newState = updateMap(newState, "error", "getShopInsurances", error);
      return newState;
    case ADD_CO_INSURED_SUCCESS:
      return newState.deleteIn(["updating", insuranceId, articleCode]);
    case SIGN_NEW_INSURANCE_SUCCESS:
      newState = updateMap(newState, "updating", "signNewInsurance", false);
      return newState;
    case ORDER_KEY_TABS_SUCCESS:
      return updateMap(newState, "updating", "keyTab", false);
    case CO_INSURANCE_PRICE_LOOKUP_SUCCESS:
      const { annualPrice, remainingAnnualPrice } = coInsurancePriceData;
      newState = updateMap(newState, "updating", "coInsurancePrice", false);
      newState = updateMap(
        newState,
        "coInsurancePrice",
        "annualPrice",
        annualPrice
      );
      newState = updateMap(
        newState,
        "coInsurancePrice",
        "endDate",
        remainingAnnualPrice.end
      );
      newState = updateMap(
        newState,
        "coInsurancePrice",
        "startDate",
        remainingAnnualPrice.start
      );
      newState = updateMap(
        newState,
        "coInsurancePrice",
        "totalPrice",
        remainingAnnualPrice.totalPrice
      );

      return newState;
    case CO_INSURANCE_PRICE_LOOKUP_REQUEST:
      return updateMap(newState, "updating", "coInsurancePrice", true);
    case CO_INSURANCE_PRICE_LOOKUP_FAILURE:
      return updateMap(newState, "updating", "coInsurancePrice", false);
    default:
      return newState;
  }
};

export default insurances;
