import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import classNames from 'classnames';
import Heading from './Heading';
import LoadingSpinner from './LoadingSpinner';

function isSingleComponent(itemIds) {
  return itemIds.size === 1;
}

const ListComponent = ({
  ids = List(),
  component: Component,
  direction = 'row',
  heading,
  isLoading,
  children,
  isPrimaryHeading = true,
  isInsuranceCard = false,
  ulClass,
  liClass,
  ...rest
}) => {
  let number = 0;
  const list = ids.map((id, i) => {
    number++;
    return (
      // eslint-disable-next-line react/no-array-index-key
      <li className={liClass} key={`${id}_${i}`}>
        {isInsuranceCard && number === 2 ? (
          <div className="insurance-promotion-banner">
            <i className="icon-percentage" />
            <div className="insurance-promotion-text-container">
              <h5 className="insurance-promotion-header">
                Kombinera & få rabatt!
              </h5>
              <p className="insurance-promotion-text">
                Genom att kombinera flera försäkringar får du kombinationsrabatt
                och därmed en lägre premie.
              </p>
            </div>
          </div>
        ) : null}
        <Component id={id} isSoloCard={isSingleComponent(ids)} />
      </li>
    );
  });
  return (
    <div
      className={classNames(
        'container column flex list',
        {
          'row wrap': direction === 'row'
        },
        rest.className || ''
      )}>
      {Boolean(heading) && (
        <Heading heading={heading} isPrimary={isPrimaryHeading} />
      )}
      <LoadingSpinner size={25} isLoading={isLoading} />
      {Boolean(list.size) && (
        <ul
          className={classNames('list flex wrap', direction, {
            'content-space': direction === 'row'
          }, ulClass)}>
          {list}
          {children}
        </ul>
      )}
    </div>
  );
};

ListComponent.defaultProps = {
  isLoading: false,
  ids: List(),
  direction: 'row',
  heading: '',
  isPrimaryHeading: false
};

ListComponent.propTypes = {
  ids: PropTypes.oneOfType([
    ImmutablePropTypes.setOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    ImmutablePropTypes.mapOf(ImmutablePropTypes.listOf(PropTypes.string)),
    ImmutablePropTypes.listOf(ImmutablePropTypes.mapOf(PropTypes.string)),
    PropTypes.object
  ]),
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  direction: PropTypes.string,
  heading: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  isPrimaryHeading: PropTypes.bool
};

export default ListComponent;
