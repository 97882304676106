import classNames from "classnames";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { modalActions, userActions } from "../../actions";
import { userSelectors } from "../../selectors";
import Heading from "./Heading";
import { themes } from '../../helpers/config';

const { getUserData } = userSelectors;

class UserWelcomeMessage extends PureComponent {
  componentDidMount() {
    const { fetchUserData } = this.props;
    fetchUserData();
  }

  componentDidUpdate() {
    const { openModal, userData } = this.props;
    if (userData.get("email").length) return;

    if(themes.isAssistans() && !localStorage.hasShownMissingEmailModal) {
      openModal();
      localStorage.hasShownMissingEmailModal = true;
    }
  }

  render() {
    const { userData, heading} = this.props;
    return (
      <div className={classNames("container")}>
        {/* <Heading
          heading={`Hej ${
            userData.get("firstName") ? userData.get("firstName") : ""
          }`}
        /> */}
        <Heading heading={heading} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userData: getUserData(state)
});

const mapDispatchToProps = dispatch => ({
  fetchUserData: () => dispatch(userActions.getUserData()),
  openModal: () => dispatch(modalActions.openModal("missingEmail"))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserWelcomeMessage);
