import vehicleConstants from "../../../../constants/vehicle.constants";

const { WAGON_VEHICLE } = vehicleConstants;
export const PYRAMID_TYPE = "SLÄP";

export default {
    type: WAGON_VEHICLE,
    matchesType: (vehicle) => {
        return vehicle.data.typeCode === PYRAMID_TYPE;
    },
    isInsurable: () => true
}