import PropTypes from "prop-types";
import React from "react";
import Parser from "html-react-parser";
import Button from "../helper/Button";

const InfoCard = ({
  content,
  heading,
  button,
  path,
  openInviteFriendModal,
  ...rest
}) => (
  <div className="info-card flex column justify-between align-center" {...rest}>
    <h4 className="align-always-center">{heading}</h4>
    <span className="align-always-center">{Parser(content)}</span>
    <Button onClick={openInviteFriendModal} path={path}>
      {button}
    </Button>
  </div>
);

InfoCard.defaultProps = {
  path: "",
  openInviteFriendModal: () => {}
};

InfoCard.propTypes = {
  button: PropTypes.string.isRequired,
  path: PropTypes.string,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  openInviteFriendModal: PropTypes.func
};

export default InfoCard;
