import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { userActions } from "../../actions";
import Heading from "../../components/helper/Heading";
import { userSelectors } from "../../selectors";
import {
  UserDetails,
  DetailsForm
} from "./themes"
import LoadingSpinner from "../../components/helper/LoadingSpinner";
import lang from "../../helpers/lang-constants";

const { getUserData, getUpdating } = userSelectors;
const language = lang.customer.views.details.index;

class Details extends PureComponent {
  componentDidMount() {
    const { fetchUserData } = this.props;
    fetchUserData();
  }

  render() {
    const { isLoading, userData, updateUser } = this.props;
    return (
      <div className="container details flex column">
        <Heading heading={language.title} />
        <LoadingSpinner size={25} isLoading={isLoading} />
        {!isLoading && (
          <div className="container details form flex column">
            <UserDetails userData={userData} />
            <DetailsForm userData={userData} updateUser={updateUser} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userData: getUserData(state),
  isLoading: getUpdating(state, "fetchUser")
});

const mapDispatchToProps = dispatch => ({
  fetchUserData: () => dispatch(userActions.getUserData())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Details);
