import { env } from "../../../helpers/config";
import { COUNTRY_CODES } from "../constants";
import BaseApi from "../base_urls.skeleton";

const base_urls = new BaseApi();
const country_code =  COUNTRY_CODES.DK

if(env.isDevelopment()) {
    base_urls.API = "http://api.test-cluster.assistansbolaget.nu";
    base_urls.AUTH_API = "http://auth.test-cluster.assistansbolaget.nu";
    base_urls.WP = "http://dk.test-cluster.assistansbolaget.nu";
    base_urls.ETRACK = "https://system.etrack1.com/formbuilder/ContactForm.aspx?fid=c03a58d0-1596-451e-9ace-c523a21c4a43&catondetail=1&group=nettsted";
    base_urls.COUNTRY_CODE = country_code;
    base_urls.POLICY_LINK = 'http://dk.test-cluster.assistansbolaget.nu/villkor';
} else if(env.isStaging()) {
    base_urls.API = "http://api-prod.test-cluster.assistansbolaget.nu";
    base_urls.AUTH_API = "http://auth-prod.test-cluster.assistansbolaget.nu";
    base_urls.WP = "http://dk.test-cluster.assistansbolaget.nu";
    base_urls.ETRACK = "https://system.etrack1.com/formbuilder/ContactForm.aspx?fid=c03a58d0-1596-451e-9ace-c523a21c4a43&catondetail=1&group=nettsted";
    base_urls.COUNTRY_CODE = country_code;
    base_urls.POLICY_LINK = 'http://dk.test-cluster.assistansbolaget.nu/villkor';
} else if(env.isProduction()) {
    base_urls.API = "http://api.test-cluster.assistansbolaget.nu";
    base_urls.AUTH_API = "https://auth-cluster.assistansbolaget.nu";
    base_urls.WP = "http://dk.test-cluster.assistansbolaget.nu";
    base_urls.ETRACK = "https://system.etrack1.com/formbuilder/ContactForm.aspx?fid=c03a58d0-1596-451e-9ace-c523a21c4a43&catondetail=1&group=nettsted";
    base_urls.COUNTRY_CODE = country_code;
    base_urls.POLICY_LINK = 'http://dk.test-cluster.assistansbolaget.nu/villkor'
}

export default base_urls;