import React, { PureComponent } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  userActions
} from "../../actions";
import Heading from "../../components/helper/Heading";
import { routesConstants } from "../../constants";
import { history, device } from "../../helpers";
import lang from "../../helpers/lang-constants";
import { LoginComponent } from "../../components/login/index";

const { LOGIN } = routesConstants;
const language = lang.customer.views.login_page.index;

class LoginPage extends PureComponent {
  componentDidMount() {
    const { logout } = this.props;
    if (!history.location.pathname.includes(LOGIN)) logout();
  }

  render() {
    return (
      <div className="container login flex column justify-center align-center align-always-center">
        <Heading heading={language.heading_text} />
        <Route path={LOGIN} component={LoginComponent} />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  const { logout } = userActions;
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(LoginPage);
