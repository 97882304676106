import Modal from '@material-ui/core/Modal';
import React from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions';
import { modalSelectors } from '../../selectors';
import CoInsuredModal from './CoInsuredModal';
import DebitCardModal from './DebitCardModal';
import InviteFriendModal from './InviteFriendModal';
import MissingEmailModal from './MissingEmailModal';
import OrderKeyTabsModal from './OrderKeyTabsModal';
import BankIdModal from './BankIdModal';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const modals = {
  coInsured: CoInsuredModal,
  debitCard: DebitCardModal,
  missingEmail: MissingEmailModal,
  inviteFriend: InviteFriendModal,
  orderKeyTabs: OrderKeyTabsModal,
  bankId: BankIdModal
};

const { getOpen, getComponent } = modalSelectors;

class ModalContainer extends React.Component {
  render() {
    const { 
      isOpen, 
      component,
      closeModal 
    } = this.props;
    const Component = modals[component];

    return (
      <div>
        {component !== 'default' && (
          <Modal
            className="modal flex align-center justify-center"
            open={isOpen}
            onClose={component !== 'bankId' ? closeModal : undefined}>
            <Component closeModal={closeModal} id="overlay-modal" />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isOpen: getOpen(state),
  component: getComponent(state)
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(modalActions.closeModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalContainer);
