import React from 'react';

/**
 * This component renders a row of fields, where each field can be considered as a column.
 * The columns can contain a title and content and can be placed at three different positions,
 * left, center and right. To use the component pass your columns as children.
 * In order for the component to work you need to pass a key property, containing position and
 * type.
 * For example:
 *  <RowContainer>
 *    <span itemType="leftTitle" className="label-bold">title</span>
 *    <span itemType="leftContent" className="content">title</span>
 *  </RowContainer>
 *
 * The available keys are:
 *  - leftTitle
 *  - leftContent
 *  - centerTitle
 *  - centerContent
 *  - rightTitle
 *  - rightContent
 *
 * To pass custom styles or classnames on RowContainer simply add them to your markup,
 * <RowContainer className="..." style={{ ... }} />
 *
 * @param {*} param0
 */

const RowContainer = ({ children, ...rest }) => {
  const childrenObj = {
    leftTitle: null,
    leftContent: null,
    centerTitle: null,
    centerContent: null,
    rightTitle: null,
    rightContent: null
  };
  const mapChildren = () => {
    if (!children) return;
    if (!children.length) childrenObj[children.props.itemType] = children;
    else
      children.forEach(child => {
        childrenObj[child.props.itemType] = child;
      });
  };
  mapChildren();

  const getChildrenList = () => {
    const childrenList = [];
    for (let i = 0; i < Object.values(childrenObj).length - 1; i++) {
      const values = Object.values(childrenObj);

      if (values[i] || values[i + 1]) {
        let key = values[i] ? values[i].props.itemType : null;
        key = !key ? values[i + 1].props.itemType : key;
        childrenList.push(
          <div className="label-container" key={key}>
            {values[i]}
            {values[i + 1]}
          </div>
        );
      }
      i++;
    }
    return childrenList;
  };
  return (
    <div
      className={`row-container align-center ${rest.className || ''}`}
      style={rest.style ? rest.style : {}}>
      {getChildrenList()}
    </div>
  );
};
export default RowContainer;
