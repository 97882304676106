import React from "react";
import InlineBanner from "./InlineBanner";
import Button from "../helper/Button";
import { routesConstants } from "../../constants";
import lang from "../../helpers/lang-constants";

const { INVOICES } = routesConstants;
const language = lang.customer.components.banner.banner_direct_debit;

const BannerDirectDebit = ({ heading, content, signDirectDebit }) => (
  <div className="direct-debit-banner">
    <InlineBanner
      heading={heading}
      content={
        content
          ? `${content}`
          : `${language.content_text}`
      }
      icon="icon-autogiro gold-icon"
    >
      <Button path={INVOICES}>{language.button.text}</Button>
    </InlineBanner>
  </div>
);

export default BannerDirectDebit;
