import { vehicleConstants } from "../../../../../constants";

const { LIGHT_TRUCK, PERSONAL_VEHICLE } = vehicleConstants;

export default {
  types: ["LB"],
  coverage: [LIGHT_TRUCK, PERSONAL_VEHICLE],
  isCoInsurable: true,
  recommendationTitle: "Vägassistans Lätt lastbil & Bil",
  recommendationText:
    "Vår vägassistansförsäkring Lätt lastbil & Bil är ett utmärkt val för dig som kör både lätt lastbil och personbil. Genom att kombinera flera försäkringar hos oss får du som kund 30% rabatt på den totala försäkringspremien."
};
