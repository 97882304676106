import * as isMobile from "ismobilejs";

export function isIosPhone() {
  return isMobile.apple.phone;
}

export function isAndroidPhone() {
  return isMobile.android.phone;
}

export function isPhone() {
  return isMobile.phone;
}

export function isAndroid() {
  return isMobile.android.device;
}

export function isIos() {
  return isMobile.apple.device;
}