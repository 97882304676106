import { apiConstants } from "../constants";

const { WP_URL, WP_CUSTOM_FIELDS_URL } = apiConstants;

function getWpPosts(postType) {
  return fetch(`${WP_URL}/${postType}?per_page=100&_embed`)
    .then(handleResponse)
    .then(data => data);
}

function handleResponse(response) {
  return response.text().then(text => text && JSON.parse(text));
}

function getAcfWpPosts(postType) {
  return fetch(`${WP_CUSTOM_FIELDS_URL}/${postType}`)
    .then(handleResponse)
    .then(data => data);
}

export default {
  getWpPosts,
  getAcfWpPosts
};
