import { vehicleConstants } from "../../../../../constants";

const { NOT_COVERED_VEHICLE } = vehicleConstants;

export default {
  types: ["NOT_COVERED_TYPE"],
  coverage: [NOT_COVERED_VEHICLE],
  recommendationTitle: "",
  recommendationText: ""
};
