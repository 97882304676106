import { fromJS } from "immutable";
import jwtDecode from "jwt-decode";
import { familyConstants } from "../constants";
import { updateMap, getUser } from "./functions";

const {
  FETCH_CO_INSURED_USER_DATA_REQUEST,
  FETCH_CO_INSURED_USER_DATA_SUCCESS,
  FETCH_CO_INSURED_USER_DATA_FAILURE,
  ADD_FRIEND_REQUEST,
  ADD_FRIEND_SUCCESS,
  ADD_FRIEND_FAILURE,
  CLEAR_CURRENT_CUSTOMER_CODE,
  SET_CURRENT_CUSTOMER_CODE,
  FETCH_FAMILY_IN_HOUSE_HOLD_REQUEST,
  FETCH_FAMILY_IN_HOUSE_HOLD_SUCCESS,
  FETCH_FAMILY_IN_HOUSE_HOLD_FAILURE
} = familyConstants;

const initialState = fromJS({
  updating: {},
  statusMessage: {},
  error: "",
  familyInHouseHoldData: {}
});

const family = (state = initialState, action) => {
  const { error, data, type, familyInHouseHold } = action;
  let newState = state;
  switch (type) {
    case ADD_FRIEND_REQUEST:
      return updateMap(newState, "updating", "addFriend", true);
    case FETCH_FAMILY_IN_HOUSE_HOLD_REQUEST:
      return updateMap(newState, "updating", "fetchHouseHold", true);

    case FETCH_FAMILY_IN_HOUSE_HOLD_SUCCESS: {
      newState = updateMap(newState, "updating", "fetchHouseHold", false);
      if (!familyInHouseHold.relations) return newState;
      if (!jwtDecode(getUser().token)) return newState;
      const userCustomerCode = jwtDecode(getUser().token).CustomerCode;
      familyInHouseHold.relations.forEach(relation => {
        if (relation.socialNumber.substring(2) !== userCustomerCode) {
          relation.socialNumber = relation.socialNumber.substring(2);
          newState = updateMap(
            newState,
            "familyInHouseHoldData",
            relation.socialNumber,
            relation
          );
        }
      });
      return newState;
    }
    case ADD_FRIEND_SUCCESS:
      newState = updateMap(newState, "updating", "addFriend", false);
      newState = updateMap(
        newState,
        "statusMessage",
        "addFriend",
        data.statusMessage
      );
      return newState;

    case FETCH_FAMILY_IN_HOUSE_HOLD_FAILURE:
      newState = updateMap(newState, "updating", "fetchHouseHold", false);
      newState = newState.set("error", error);
      return newState;

    case ADD_FRIEND_FAILURE:
      newState = updateMap(newState, "updating", "addFriend", false);
      newState = newState.set("error", error);
      return newState;

    default:
      return newState;
  }
};

export default family;
