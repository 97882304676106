import { vehicleConstants } from "../../../../../constants";

const {
    PERSONAL_VEHICLE,
    MOTORCYCLE
} = vehicleConstants;

export default {
    types: [
        'BM',
        'BM2',
        'BM3'
    ],
    coverage: [
        PERSONAL_VEHICLE,
        MOTORCYCLE
    ],
    isCoInsurable: true
}
