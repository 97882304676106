import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import lang from "../../../../helpers/lang-constants";

const language = lang.customer.views.details.user_details;

const UserDetails = ({ userData }) => {
  const lastName = userData.get('lastName');
  const firstName = userData.get('firstName');
  const city = userData.get('city');
  const zipCode = userData.get('zipCode');
  const streetName = userData.get('streetAddress');

  return (
    <div className="flex user-details">
      <div className="inner-container flex column flex-grow">
        <div className="label-container">
          <span className="label-bold">{language.name_text}</span>
          <span id="first-name" className="text-normal">
            {firstName} {lastName}
          </span>
        </div>
        <div className="label-container">
          <span className="label-bold">{language.address_text}</span>
          <span id="address" className="text-normal">
            {streetName}
          </span>
        </div>
        <div className="label-container">
          <span className="label-bold">{language.city_text}</span>
          <span id="address" className="text-normal">
            {city}
          </span>
        </div>
      </div>
      <div className="inner-container flex column">
        <div className="label-container">
          <span className="label-bold">{language.zip_code_text}</span>
          <span id="zip-code" className="text-normal">
            {zipCode}
          </span>
        </div>
        <div className="label-container">
          <span className="label-bold">{language.country_text}</span>
          <span id="country" className="text-normal">
            {language.country}
          </span>
        </div>
      </div>
    </div>
  );
};

UserDetails.propTypes = {
  userData: ImmutablePropTypes.mapOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  )
};

export default UserDetails;
