import { isValid } from "redux-form";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { InitialInsuranceForm } from "sign-insurance-components";
import { insuranceActions } from "../../actions";
import { modalConstants } from "../../constants";
import { insuranceSelectors, modalSelectors } from "../../selectors";
import Button from "../helper/Button";
import LoadingSpinner from "../helper/LoadingSpinner";
import lang from "../../helpers/lang-constants";
import { themes } from "../../helpers/config";
import BaseModal from "./BaseModal";
import { CoInsuredComponent } from "./themes";
import CoInsuredPrice from "./CoInsuredPrice";
import formatDate from "../../helpers/formatter";

const { MODAL_NOT_FOCUSED } = modalConstants;
const { getInsuranceId } = modalSelectors;
const { getUpdating, getCoInsurancePrice } = insuranceSelectors;
const language = lang.customer.components.modal.co_insured_modal;

class CoInsuredModal extends BaseModal {
  componentDidMount() {
    const { coInsurancePriceLookup, insuranceId } = this.props;
    coInsurancePriceLookup(insuranceId);
  }

  render() {
    const {
      addCoInsured,
      isAddingCoInsured,
      closeModal,
      addCoInsuredNorway,
      familyMember,
      isCoInsurancePriceLoading,
      coInsurancePrice
    } = this.props;
    const { annualPrice, totalPrice, startDate, endDate } = coInsurancePrice;
    const period = lang.formatString(
      language.pricing.to,
      `${formatDate(startDate)}`,
      `${formatDate(endDate)}`
    );
    const totalPriceText = `${totalPrice} kr`;
    const annualPriceText = `${Math.round(annualPrice)} kr`;
    let { title } = language;
    let { description } = language;

    if (familyMember && themes.isAssistans()) {
      title = lang.formatString(
        language.title_family_member,
        familyMember.firstName.split(" ")[0]
      );
      description = language.description_family_member;
    }

    return (
      <div
        ref={this.targetRef}
        className="modal content noScroll"
        tabIndex={MODAL_NOT_FOCUSED}
      >
        <h4 className="dialog-title flex row justify-between">
          {title}
          <i
            className="fal fa-times-circle modal-close color-gold"
            onClick={closeModal}
            onKeyPress={closeModal}
            tabIndex={-1}
            role="button"
          />
        </h4>

        <div className="dialog-content dialog-form coInsuredModal-text">
          <span>{description}</span>
          {isCoInsurancePriceLoading ? (
            <LoadingSpinner isLoading={isCoInsurancePriceLoading} size={25} />
          ) : (
            <CoInsuredPrice
              annualPrice={annualPriceText}
              remainingPrice={totalPriceText}
              period={period}
            />
          )}
          <CoInsuredComponent />
          <span className="disclaimer-text">{language.disclaimer}</span>
        </div>
        <div className="dialog-actions flex justify-center coInsuredModal-button">
          <Button
            type="button"
            onClick={() => {
              themes.isNorway() ? addCoInsuredNorway() : addCoInsured();
            }}
            btnType="primary"
          >
            <LoadingSpinner
              text={language.submit_button.text}
              isLoading={isAddingCoInsured}
            />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAddingCoInsured: getUpdating(state, "insuranceId"),
  isValid: isValid("completeCustomerForm")(state),
  isCoInsurancePriceLoading: getUpdating(state, "coInsurancePrice"),
  coInsurancePrice: getCoInsurancePrice(state, "coInsurancePrice").toJSON(),
  insuranceId: getInsuranceId(state),
  familyMember: modalSelectors.getFamilyMember(state, "")
});

const mapDispatchToProps = dispatch => ({
  addCoInsured: () => dispatch(insuranceActions.addCoInsured()),
  addCoInsuredNorway: () => dispatch(insuranceActions.addCoInsuredNorway()),
  coInsurancePriceLookup: insuranceId =>
    dispatch(insuranceActions.coInsurancePriceLookup(insuranceId))
});

CoInsuredModal.defaultProps = {
  isAddingCoInsured: false
};

CoInsuredModal.propTypes = {
  addCoInsured: PropTypes.func.isRequired,
  isAddingCoInsured: PropTypes.bool,
  closeModal: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoInsuredModal);
