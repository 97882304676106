import { authHeader, history } from "../helpers";
import { apiConstants, routesConstants } from "../constants";
import handleInsuranceResponse from "./handlers/insurances/insuranceHandler";

const { LOGIN } = routesConstants;

function logout() {
  localStorage.removeItem("user");
  history.push(LOGIN);
}

function handleResponse(response) {
  if (process.env.NODE_ENV === "development") console.log("response", response);
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (response.ok) return data;
    if (response.status === 401) {
      logout();
      return Promise.reject({
        unauthorized: true
      });
    }
    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  });
}

function getInsurances() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(apiConstants.API.USER.INSURANCES, requestOptions)
    .then(handleResponse)
    .then(data => {
      if (process.env.NODE_ENV === "development") console.log("data", data);
      return handleInsuranceResponse(data);
    });
}

function addCoInsured(payload) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(payload)
  };
  return fetch(apiConstants.API.USER.COINSURANCES, requestOptions)
    .then(response => response.text().then(text => text && JSON.parse(text)))
    .then(data => {
      if (process.env.NODE_ENV === "development")
        console.log("test data", data);
      return data;
    });
}
//Add coinsured with details for Norway
function addCoInsuredDetailed(payload) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(payload)
  };
  return fetch(apiConstants.API.USER.COINSURANCES, requestOptions)
    .then(response => response.text().then(text => text && JSON.parse(text)))
    .then(data => {
      if (process.env.NODE_ENV === "development")
        console.log("test data", data);
      return data;
    });
}

const signNewInsurance = payload => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(payload)
  };
  return fetch(apiConstants.API.USER.INSURANCES, requestOptions)
    .then(response => response.text().then(text => text && JSON.parse(text)))
    .then(data => data);
};

const orderKeyTabs = numberOfKeyTabs => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(numberOfKeyTabs)
  };
  return fetch(apiConstants.API.USER.ORDER_KEY_TABS, requestOptions)
    .then(handleResponse)
    .then(data => data);
};

function coInsurancePriceLookup(articleCode, to) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  const url = apiConstants.API.INSURANCE.PRICEINFO(articleCode, to);
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(data => data);
}

export default {
  getInsurances,
  addCoInsured,
  orderKeyTabs,
  signNewInsurance,
  addCoInsuredDetailed,
  coInsurancePriceLookup
};
