import { Map } from "immutable";

const getLoading = ({ banner }) => banner.get("loading", false);
const getBanner = ({ banner }, type) => banner.getIn(["banners", type], Map());
const getError = ({ banner }) => banner.get("error", "");

export default {
  getLoading,
  getBanner,
  getError
};
