import { apiConstants, errorConstants } from "../constants";
import { fetch } from "../helpers/fetch";

function handleResponse(response) {
  return response.json().then((data) => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(data);
    } else if(response.status < 500) {
      var error = new Error(data.details || data)
      error.response = response
      return Promise.reject(error);
    } else {
      return Promise.reject(new Error(errorConstants.GENERIC_ERROR_MESSAGE));
    }
  });
}

function authenticate(personalNumber) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      personalNumber
    })
  };
  return fetch(apiConstants.AUTH_API.BANKID.AUTHENTICATE, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function collect(orderRef) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    retries: 5,
    isRetryDelayExponential: true,
    body: JSON.stringify({
      orderRef
    })
  };
  return fetch(apiConstants.AUTH_API.BANKID.COLLECT, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function cancel(orderRef) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      orderRef
    })
  };

  return fetch(apiConstants.AUTH_API.BANKID.CANCEL, requestOptions)
    .then(handleResponse)
    .then(cancelResponse => {
      localStorage.removeItem("orderRef");
      return cancelResponse;
    }
  );
}

export default {
  authenticate,
  collect,
  cancel
};
