import { magazinesConstants } from "../constants";
import { wpService } from "../services";

const {
  GET_MAGAZINES_REQUEST,
  GET_MAGAZINES_SUCCESS,
  GET_MAGAZINES_FAILURE
} = magazinesConstants;

function getMagazines() {
  return dispatch => {
    dispatch(request());
    wpService
      .getWpPosts("magazine")
      .then(
        magazines => dispatch(success(magazines)),
        error => dispatch(failure(error))
      );
  };
}

const request = () => ({
  type: GET_MAGAZINES_REQUEST
});
const success = magazines => ({
  type: GET_MAGAZINES_SUCCESS,
  magazines
});
const failure = error => ({
  type: GET_MAGAZINES_FAILURE,
  error
});

export default {
  getMagazines
};
