import React from "react";
import { connect } from "react-redux";
import Button from "../../helper/Button";
import { routesConstants  } from "../../../constants";
import { 
  bankIdActions,
} from "../../../actions";
import history from "../../../helpers/history";
import lang from "../../../helpers/lang-constants";
import bankid_logo from "../../../assets/images/bankid_vector_black.svg";

const { BANKID_SIGN } = routesConstants;
const language = lang.customer.components.login.bankid.bankid_mobile_login;

const BankIdMobileLogin = ( { activateBankIdApplication } ) => {
  return (
    <div className="content flex column mobile-login-content">
      <span>
        {language.description_text}
        <br />
        <br />
        {language.label_text}
      </span>
      <div className="flex column justify-center align-center">
        <Button
            type="submit"
            onClick={() => activateBankIdApplication()}
        >
          {language.open_app_button.text}
          <img className="button-logo" src={bankid_logo}></img>
        </Button>
        <Button
            type="submit"
            onClick={() => {
                history.push(BANKID_SIGN);
            }}
        >
          {language.default_login_button.text}
        </Button>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
    const { openBankidApplication } = bankIdActions;
    return {
        activateBankIdApplication: () => {
            dispatch(openBankidApplication());
        }
    };
};

export default connect(
  null,
  mapDispatchToProps
)(BankIdMobileLogin);