import React from "react";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { isIosPhone } from "../../helpers/device";

class BaseModal extends React.Component {
    targetRef = React.createRef();
    targetElement = null;
    scrollTop = null;
    position = null;

    componentDidMount() {
        if(!isIosPhone()) {
            return;
        }
        this.scrollTop = document.body.scrollTop;
        this.position = document.body.style.position;
        document.body.style.position = 'fixed';
        this.targetElement = this.targetRef.current;
        disableBodyScroll(this.targetElement);
    }

    componentWillUnmount() {
        document.body.style.position = this.position;
        if(this.scrollTop) {
            document.body.scrollTop = this.scrollTop;
        }
        clearAllBodyScrollLocks();
    }
}

export default BaseModal;