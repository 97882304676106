export default {
  PERSONAL_VEHICLE: "PERSONAL_VEHICLE",
  VETERAN_VEHICLE: "VETERAN_VEHICLE",
  LIGHT_TRUCK: "LIGHT_TRUCK_VEHICLE",
  MOTORCYCLE: "MOTORCYCLE_VEHICLE",
  WAGON_VEHICLE: "WAGON_VEHICLE",
  CARAVAN_VEHICLE: "CARAVAN_VEHICLE",
  RV_VEHICLE: "RV_VEHICLE",
  NOT_COVERED_VEHICLE: "NOT_COVERED_VEHICLE"
};
