import personalVehicle, { PYRAMID_TYPE as PB_PYRAMID_TYPE } from "./personalVehicle";

export const PYRAMID_TYPE = PB_PYRAMID_TYPE;

export default {
    type: 'VETERAN_VEHICLE',
    matchesType: (vehicle) => {
        return vehicle.data.typeCode === PYRAMID_TYPE &&
            (new Date().getFullYear() - Number(vehicle.data.modelYear)) >= 30;
    },
    isInsurable: personalVehicle.isInsurable
};