import { fromJS, Map } from "immutable";

/**
 * Checks wether the provided key exists or not. If the key exists it will merge the
 * new Map with the old one, else it will create a new Map with the given key.
 *
 *
 * @param {Immutable Map} state
 * @param {String} collection
 * @param {String} key
 * @param {*} value
 */
export const updateMap = (state, collection, key, value) => {
  if (state.get(collection).has(key))
    return state.mergeIn([collection, key], fromJS(value));
  return state.setIn([collection, key], fromJS(value));
};

export const updateDeepNestedMap = (state, collection, key1, key2, value) => {
  if (
    state.get(collection, Map()) &&
    state.getIn([collection, key1], Map()).has(key2)
  ) {
    return state.mergeIn([collection, key1, key2], fromJS(value));
  }
  return state.setIn([collection, key1, key2], fromJS(value));
};

export const getUser = () => JSON.parse(localStorage.getItem("user"));
