import { footerConstants } from "../constants";
import { wpService } from "../services";

const {
  GET_FOOTER_FIELDS_REQUEST,
  GET_FOOTER_FIELDS_SUCCESS,
  GET_FOOTER_FIELDS_FAILURE
} = footerConstants;

const request = () => ({
  type: GET_FOOTER_FIELDS_REQUEST
});
const success = data => ({
  type: GET_FOOTER_FIELDS_SUCCESS,
  data
});
const failure = error => ({
  type: GET_FOOTER_FIELDS_FAILURE,
  error
});

const fetchFooterFields = () => dispatch => {
  dispatch(request());
  wpService
    .getAcfWpPosts("options/options")
    .then(data => dispatch(success(data)), error => dispatch(failure(error)));
};

export default {
  fetchFooterFields
};
