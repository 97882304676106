import vehicleConstants from "../../../../constants/vehicle.constants";

const { MOTORCYCLE } = vehicleConstants;
export const PYRAMID_TYPE = "MC";

export default {
    type: MOTORCYCLE,
    matchesType: (vehicle) => {
        return vehicle.data.typeCode === PYRAMID_TYPE;
    },
    isInsurable: () => true
}