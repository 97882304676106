import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { validation, history } from "../../../helpers";
import Button from "../../helper/Button";
import Input from "../../helper/Input";
import LoadingSpinner from "../../helper/LoadingSpinner";
import lang from "../../../helpers/lang-constants";
import bankid_logo from "../../../assets/images/bankid_vector_black.svg";
import { authSelectors } from "../../../selectors";
import { isPhone } from "../../../helpers/device";
import { routesConstants  } from "../../../constants";

const { LOGIN } = routesConstants;
const { getLoading } = authSelectors;
const { required, validSSN, normalizeSSN } = validation;
const language = lang.customer.components.login.bankid.bankid_login_form;

const BankIdLoginForm = ({
  requestBankIdAuth,
  handleSubmit,
  isLoading
}) => {
  return (
    <div className="content flex column">
      <span>
        {language.description_text}
        <br />
        <br />
        {language.label_text}
      </span>
      <form
        text="Logga in"    
        className="login-form flex column align-center justify-center"
      >
        <div className="login-form-content">
          <Field
            name="login"
            component={Input}
            placeholder={language.placeholder}
            type="tel"
            showErrorOnSubmit
            validate={[validSSN, required]}
            normalize={normalizeSSN}
            autoComplete="off"
          />
        </div>
        <div className="flex column justify-center align-center">
          <Button
            type="submit"
            onClick={handleSubmit(() => requestBankIdAuth())}
          >
            <LoadingSpinner isLoading={isLoading} text={language.submit_button.text} />
            <img className="button-logo" src={bankid_logo}></img>
          </Button>
          {isPhone() &&
          <a onClick={() => history.push(LOGIN)}>
            {language.cancel_button.text}
          </a>
          }
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: getLoading(state).get("bankIdAuth", false)
});

export default reduxForm({
  form: "loginForm"
})(connect(
  mapStateToProps
)(BankIdLoginForm));
