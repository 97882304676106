import { errorConstants, apiConstants } from "../constants";

function handleResponse(response) {
    return response.json().then((data) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(data);
      } else {
        return Promise.reject(new Error(errorConstants.GENERIC_ERROR_MESSAGE));
      }
    });
}

function login(token) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: token
      })
    };
    return fetch(apiConstants.API.LOGIN.TOKEN, requestOptions)
      .then(handleResponse)
      .then(data => {
        return data;
      });
}

export default {
    login
}