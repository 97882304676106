import formatDate from "../../helpers/formatter";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import InfoCard from "./InfoCard";
import { modalActions, invoiceActions } from "../../actions";
import { invoiceSelectors } from "../../selectors";
import {
  routesConstants,
  isCreditCard,
  isDirectDebit as isPaymentDirectDebit
} from "../../constants";
import lang from "../../helpers/lang-constants";
import VerticalPromotionalBanner from "../../components/banner/VerticalPromotionalBanner"

const { getNextInvoice, getPaymentMethod } = invoiceSelectors;
const { INVOICES, SHOP, HOME, INSURANCES } = routesConstants;
const language = lang.customer.components.info_card.info_card_container;

class InfoCardContainer extends PureComponent {
  componentDidMount() {
    const { fetchInvoices } = this.props;
    fetchInvoices();
  }

  render() {
    const { invoice, openInviteFriendModal, paymentMethod } = this.props;

    const isDirectDebit = isPaymentDirectDebit(paymentMethod);
    const isCardDebit = isCreditCard(paymentMethod);
    const collectedInvoiceData = invoice.get("collectedInvoiceData");
    const remainingAmount = invoice.get("remainingAmount");
    const dueDate = formatDate(invoice.get("dueDate"));
    return (
      <div className="info-card-container container row flex">
        <InfoCard
          heading={!isCardDebit && !isDirectDebit ?
          language.invoice_card.heading_text : language.family_insurance_card.heading_text}
          button={
            !isCardDebit && !isDirectDebit
              ? language.invoice_card.button_text
              : language.family_insurance_card.button_text
          }
          path={!isCardDebit && !isDirectDebit ? INVOICES : INSURANCES}
          content={
            isCardDebit || isDirectDebit
              ? language.family_insurance_card.content_text
              : collectedInvoiceData
                ? lang.formatString(
                  language.invoice_card.content_text.incoming_invoice_text,
                `<strong>${remainingAmount}</strong>`,
                `<strong>${dueDate}</strong>.`
              )
              : language.invoice_card.content_text.my_invoices_text
          }
        />
        <InfoCard
          heading={isDirectDebit ?
            language.sign_insurance_card.heading_text
            : language.direct_debit_card.heading_text}
          button={isDirectDebit ?
            language.sign_insurance_card.button_text
            : language.direct_debit_card.button_text}
          path={isDirectDebit ? SHOP : INVOICES}
          content={
            isDirectDebit
              ? language.sign_insurance_card.content_text
              : language.direct_debit_card.content_text
          }
        />
        <InfoCard
          heading={language.invite_friend_card.heading_text}
          button={language.invite_friend_card.button_text}
          openInviteFriendModal={openInviteFriendModal}
          content={language.invite_friend_card.content_text}
        />
        <VerticalPromotionalBanner
          className="vertical-promotional-banner-mobile"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  invoice: getNextInvoice(state),
  paymentMethod: getPaymentMethod(state)
});

const mapDispatchToProps = dispatch => ({
  fetchInvoices: () => dispatch(invoiceActions.getInvoices()),
  openInviteFriendModal: () => dispatch(modalActions.openModal("inviteFriend"))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoCardContainer);
