import vehicleConstants from "../../../../constants/vehicle.constants";
import { PYRAMID_TYPE as WAGON_PYRAMID_TYPE } from "./wagonVehicle";

const { CARAVAN_VEHICLE } = vehicleConstants;
export const PYRAMID_TYPE = WAGON_PYRAMID_TYPE;

export default {
    type: CARAVAN_VEHICLE,
    matchesType: (vehicle) => {
        return vehicle.data.typeCode === WAGON_PYRAMID_TYPE &&
            (vehicle.technicalData.chassi1Code === '154' ||
            vehicle.technicalData.chassi2Code === '154')
    },
    isInsurable: () => true
}