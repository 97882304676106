import React from "react";
import PropTypes from "prop-types";
import lang from "../../helpers/lang-constants";
import { themes } from "../../helpers/config";

const language = lang.customer.components.footer.footer;

// eslint-disable-next-line react/prop-types
const Footer = ({ fields, children }) => (
  <div className="site-footer">
    <div className="container container-width row space-between flex align-center wrap contact-us">
      <div className="contact-us">
        <h2>{fields.get("footer_heading")}</h2>
      </div>
      <div className="contact-us icon-row wrap justify-around flex row">
        <div className="contact-us icon-column wrap flex column align-center">
          <i className={`${fields.get("footer_icon_1")} fa-3x color-gold`} />
          <span>
            <a
              href={fields.getIn(["footer_icon_1_text", "url"])}
              className="color-gold no-text-wrap flex row align-center upper-case"
            >
              {fields.getIn(["footer_icon_1_text", "title"])}
              <i className="fas fa-chevron-right color-gold" />
            </a>
          </span>
        </div>
        <div className="contact-us icon-column wrap flex column align-center">
          <i className={`${fields.get("footer_icon_2")} fa-3x color-gold`} />
          <span>
            <a href={`tel:${fields.get("phone_number")}`}>
              <span className="color-gold">{`${fields.get(
                "phone_number",
                ""
              )}`}</span>
            </a>
          </span>
        </div>
      </div>
    </div>
    {children}
    <div className="container container-width row flex align-center justify-evenly wrap footer-mobile">
      <div className="footer-links-content row flex align-center justify-around">
        <a href={`tel:${fields.get("phone_number")}`} className="footer-links">
          <span className="color-gold">{`Tel ${fields.get(
            "phone_number",
            ""
          )}`}</span>
        </a>
        <a
          href={`${fields.getIn(["cookies_link", "url"])}`}
          className="footer-links"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="color-white">{language.cookie_info_text}</span>
        </a>
        {themes.isAssistans() && (
          <a
            href={`${fields.getIn(["subscribe_link", "url"])}`}
            className="footer-links"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="color-white">
              {fields.getIn(["subscribe_link", "title"])}
              <span className="color-gold">
                &nbsp;
                {language.magazine_text}
              </span>
            </span>
          </a>
        )}
      </div>
    </div>
  </div>
);

Footer.propTypes = {
  // See FooterContainer for fields prop-validation
  children: PropTypes.node.isRequired
};

export default Footer;
