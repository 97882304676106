import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

const LARGE_SPINNER = 25;

const LoadingSpinner = ({
  isLoading,
  size,
  text,
  signInsuranceLoading = false,
  ...rest
}) => (
  <div
    className={classNames("spinner flex justify-center align-center", {
      "sign-insurance-loading": signInsuranceLoading && isLoading
    })}
    style={{
      marginBottom: isLoading && size === LARGE_SPINNER ? "20px" : "0px"
    }}
    {...rest}
  >
    {!isLoading && Boolean(text.length) && (
      <span className="button-text">{text}</span>
    )}
    {isLoading && <CircularProgress size={size} />}
  </div>
);

LoadingSpinner.defaultProps = {
  isLoading: false,
  size: 14,
  text: ""
};
LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool,
  size: PropTypes.number,
  text: PropTypes.string
};

export default LoadingSpinner;
