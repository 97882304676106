import classNames from "classnames";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { userActions } from "../../actions";
import { routesConstants, vehicleConstants } from "../../constants";
import { insuranceSelectors, userSelectors } from "../../selectors";
import InlineBanner from "../banner/InlineBanner";
import Button from "../helper/Button";
import ListComponent from "../helper/ListComponent";
import VehicleCard from "./VehicleCard";
import LoadingSpinner from "../helper/LoadingSpinner";
import Heading from "../helper/Heading";
import lang from "../../helpers/lang-constants";
import {
  isVehicleTypeInsured,
  findInsuranceTypeByVehicleType,
  getInsuranceIconByVehicleType
} from "./utils";

const language = lang.customer.components.insurance.vehicles;
const {
  getUpdating,
  getRegisteredVehiclesIds,
  getRegisteredVehiclesTypes
} = userSelectors;
const { getUserInsurances } = insuranceSelectors;
const { SHOP } = routesConstants;
const { NOT_COVERED_VEHICLE } = vehicleConstants;

class Vehicles extends PureComponent {
  componentDidMount() {
    const { fetchUserVehicles } = this.props;
    fetchUserVehicles();
  }

  render() {
    const {
      isLoading,
      vehiclesIds,
      userInsurances,
      vehicleTypes,
      isLoadingInsurances
    } = this.props;
    let showPromo = false;
    let bannerHeading;
    let vehicleText;
    let vehicleIcon;
    vehicleTypes.map(vehicleType => {
      if (!isVehicleTypeInsured(userInsurances, vehicleType)) {
        const insurance = findInsuranceTypeByVehicleType(vehicleType);
        if (vehicleType !== NOT_COVERED_VEHICLE) {
          bannerHeading = insurance.recommendationTitle;
          vehicleText = insurance.recommendationText;
          vehicleIcon = getInsuranceIconByVehicleType(vehicleType);
          showPromo = true;
        }
      }
    });
    return (
      <div className={classNames("container")}>
        {showPromo &&
          !isLoadingInsurances && (
            <div className="vehicle-promo-banner">
              <InlineBanner
                heading={bannerHeading}
                content={`${vehicleText}`}
                icon={`${vehicleIcon} gold-icon inline-icon`}
              >
                <Button path={SHOP}>{language.button.text}</Button>
              </InlineBanner>
            </div>
          )}
        {(isLoadingInsurances || isLoading) && (
          <div>
            <Heading heading={language.title_text} isPrimary={Boolean(false)} />
            <LoadingSpinner isLoading={isLoadingInsurances} size={25} />
          </div>
        )}
        {vehiclesIds.size > 0 &&
          !isLoadingInsurances && (
            <ListComponent
              ids={vehiclesIds}
              component={VehicleCard}
              heading={language.title_text}
              isLoading={isLoading}
            />
          )}
      </div>
    );
  }
}

const makeMapStateToProps = () => state => ({
  isLoading: getUpdating(state, "fetchVehicles"),
  isLoadingInsurances: insuranceSelectors.getUpdating(
    state,
    "fetchUserInsurances"
  ),
  vehiclesIds: getRegisteredVehiclesIds(state),
  vehicleTypes: getRegisteredVehiclesTypes(state),
  userInsurances: getUserInsurances(state)
});

const mapDispatchToProps = dispatch => ({
  fetchUserVehicles: () => dispatch(userActions.fetchUserVehicleData())
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(Vehicles);
