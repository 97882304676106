import { getFormValues } from "redux-form";
import { bankidConstants, routesConstants, apiConstants } from "../constants";
import { bankidService, loginService } from "../services";
import { history } from "../helpers";
import { setTokenLocalStorage } from "./functions";
import snackbarActions from "./snackbar.actions";
import { authSelectors } from "../selectors";
import { timeout, getEpochTime } from "../helpers/utils";

const { HOME, LOGIN, BANKID_COLLECT, BANKID_REDIRECT } = routesConstants;
const { isOpenBankIdPending, getLoadingByType } = authSelectors;

const requestCollect = collectResponse => ({
  type: bankidConstants.COLLECT_REQUEST,
  collectResponse
});
const collectSuccess = collectResponse => ({
  type: bankidConstants.COLLECT_SUCCESS,
  collectResponse
});
const collectFailure = error => ({
  type: bankidConstants.COLLECT_FAILURE,
  error
});

const requestCancel = tokenResponse => ({
  type: bankidConstants.CANCEL_REQUEST,
  tokenResponse
});
const cancelSuccess = tokenResponse => ({
  type: bankidConstants.CANCEL_SUCCESS,
  tokenResponse
});
const cancelFailure = error => ({
  type: bankidConstants.CANCEL_FAILURE,
  error
});

const loginSuccess = () => ({
  type: bankidConstants.LOGIN_SUCCESS
});

const requestAuth = authResponse => ({
  type: bankidConstants.AUTHENTICATE_REQUEST,
  authResponse
});
const authSuccess = authResponse => ({
  type: bankidConstants.AUTHENTICATE_SUCCESS,
  authResponse
});
const authFailure = () => ({
  type: bankidConstants.AUTHENTICATE_FAILURE
});

const openBankIdPending = () => ({
  type: bankidConstants.OPEN_APP_PENDING
});
const openBankIdSuccess = (autoStartToken) => ({
  type: bankidConstants.OPEN_APP_SUCCESS,
  autoStartToken
});
const openBankIdFailure = () => ({
  type: bankidConstants.OPEN_APP_FAILURE
});

const cancel = orderRef => dispatch => {
  dispatch(requestCancel({ orderRef }));
  bankidService.cancel(orderRef).then(
    tokenResponse => {
      dispatch(cancelSuccess(tokenResponse));
    },
    error => {
      dispatch(cancelFailure(error.message));
    }
  );
};

const bankIdAuthenticate = () => (dispatch, getState) => {
  const authLoading = getLoadingByType(getState(), "bankIdAuth");

  if(authLoading) {
    console.log("Cannot start a new BankID auth when one is already running");
    return;
  }

  let personalNumber = getFormValues("loginForm")(getState());
  if (!personalNumber) return;
  personalNumber = personalNumber.login;
  dispatch(requestAuth({ personalNumber }));
  bankidService.authenticate(personalNumber)
    .then((response) => {
      dispatch(authSuccess(response));
      history.push(`${BANKID_COLLECT}/${response.orderRef}`);
    })
    .catch((err) => {
      dispatch(authFailure());
      dispatch(snackbarActions.openSnackbar("failure", err.message));
    });
};

const openBankidApplication = () => (dispatch, getState) => {
  const pending = isOpenBankIdPending(getState());

  if(pending) {
    console.log("Cannot open application while it's already opening");
    return;
  }

  dispatch(openBankIdPending());
  bankidService.authenticate()
    .then((response) => {
      dispatch(authSuccess(response));
      dispatch(openBankIdSuccess(response.autoStartToken));
      history.push(`${BANKID_REDIRECT}/${response.orderRef}`);
    })
    .catch((err) => {
      dispatch(authFailure());
      dispatch(openBankIdFailure());
      dispatch(snackbarActions.openSnackbar("failure", err.message));
    });
}

const bankIdWaitForCollect = orderRef => dispatch => {
  waitForBankIdLogin(orderRef, dispatch)
    .then(async (response) => {
      let loginResponse = await loginService.login(response.token);
      setTokenLocalStorage(loginResponse.token);
      dispatch(loginSuccess());
      history.push(HOME);
    })
    .catch((err) => {
      dispatch(authFailure());
      dispatch(snackbarActions.openSnackbar("failure", err.message));
      history.push(LOGIN);
    })
}

function waitForBankIdLogin(orderRef, dispatch) {
  return new Promise(async (resolve, reject) => {
    /**
     * As of 2019-05-24 you have aprox. 5 1/2 minutes (160 iterations) to login
     * until the bankid service will abort. This is a safety circuit breaker 
     * not to get stuck in an infinite loop
     */
    const MAX_COLLECT_TRIES = 170;
    const POLLING_INTERVAL_MS = 2000; 
    let time = getEpochTime();

    for(let i = 0; i < MAX_COLLECT_TRIES; i++) {
      dispatch(requestCollect({ orderRef }));
      try {
        let response = await bankidService.collect(orderRef);
        dispatch(collectSuccess(response));
        const { status } = response;
        if(status === "complete") {
          resolve(response);
          return;
        } else if(status === "failed") {
          reject(new Error("Kunde ej logga in, var vänlig försök igen."));
          return;
        }
      } catch(error) {
        dispatch(collectFailure(error));
        bankidService.cancel(orderRef);
        reject(new Error("Något gick fel, var vänlig försök igen."));
        return;
      }
      const elapsedTime = getEpochTime();
      await timeout(POLLING_INTERVAL_MS - (elapsedTime - time));
      time = elapsedTime;
    }
    reject("Du tog för lång tid på dig, var vänlig försök igen.");
  });
}

export default {
  bankIdAuthenticate,
  cancel,
  loginSuccess,
  bankIdWaitForCollect,
  openBankidApplication
};
