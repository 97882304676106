import { apiConstants } from "../constants";

const requestToken = customerCode => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(customerCode)
  };
  if (process.env.NODE_ENV === "development") console.log(requestOptions.body);
  return fetch(apiConstants.API.LOGIN.REQUEST_SMS_TOKEN, requestOptions)
    .then(response => response.json())
    .then(data => data);
};

const smsLogin = (token, customerCode) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      customerCode,
      token: parseInt(token, 10)
    })
  };
  if (process.env.NODE_ENV === "development")
    console.log("body", requestOptions.body);
  return fetch(
    apiConstants.API.LOGIN.LOGIN_WITH_SMS_TOKEN,
    requestOptions
  )
    .then(response => response.json())
    .then(data => data);
};

export default {
  requestToken,
  smsLogin
};
