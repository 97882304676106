import { fromJS } from "immutable";
import { modalConstants } from "../constants";

const { OPEN_MODAL, CLOSE_MODAL } = modalConstants;

const initialState = fromJS({
  open: false,
  component: "default",
  insuranceId: "",
  articleCode: "",
  orderRef: null,
  familyMember: null
});

const modal = (state = initialState, action) => {
  const {
    type,
    component,
    articleCode,
    insuranceId,
    orderRef,
    familyMember
  } = action;
  let newState = state;
  switch (type) {
    case OPEN_MODAL:
      newState = newState.set("open", true);
      newState = newState.set("component", component);
      newState = newState.set("insuranceId", insuranceId);
      newState = newState.set("articleCode", articleCode);
      newState = newState.set("orderRef", orderRef);
      newState = newState.set("familyMember", familyMember);
      return newState;
    case CLOSE_MODAL:
      newState = newState.set("open", initialState.get("open"));
      newState = newState.set("component", initialState.get("component"));
      newState = newState.set("insuranceId", initialState.get("insuranceId"));
      newState = newState.set("articleCode", initialState.get("articleCode"));
      newState = newState.set("orderRef", initialState.get("orderRef"));
      newState = newState.set("familyMember", initialState.get("familyMember"));
      return newState;
    default:
      return newState;
  }
};

export default modal;
