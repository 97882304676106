import { isAndroid, isIos } from "./device";
import { apiConstants, routesConstants  } from "../constants";
import { isSafari, isChrome, isFirefox } from "./browser";

const { MY_BASE_URL } = apiConstants;
const { BANKID_REDIRECT } = routesConstants;


export function openDeviceBankId(autoStartToken, orderRef, useUniversalLink) {
    window.location.href = getBankIdApplicationLink(autoStartToken, orderRef, useUniversalLink);
}

export function getBankIdApplicationLink(autoStartToken, orderRef, useUniversalLink) {
  let link;
  if(isAndroid()) {
    link = createAndroidLink(autoStartToken);
  } else if(isIos()) {
    link = createIosLink(autoStartToken, orderRef, useUniversalLink);
  }
  return link;
}

function createAndroidLink(autoStartToken) {
  return `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
}

function createIosLink(autoStartToken, orderRef, useUniversalLink) {
  let link;
  if(isSafari()) {
    if(useUniversalLink) {
      link = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=${encodeURIComponent(`${MY_BASE_URL}${BANKID_REDIRECT}/${orderRef}`)}`;
    } else {
      link = `bankid:///?autostarttoken=${autoStartToken}&redirect=${encodeURIComponent(`${MY_BASE_URL}${BANKID_REDIRECT}/${orderRef}`)}`;
    }
  } else if(isChrome()) {
    let redirectLink;
    if(isHttp(MY_BASE_URL)) {
      redirectLink = "googlechrome:///";
    } else {
      redirectLink = "googlechromes:///";
    }
    link = `bankid:///?autostarttoken=${autoStartToken}&redirect=${encodeURIComponent(redirectLink)}`;
  } else if(isFirefox()) {
    link = `bankid:///?autostarttoken=${autoStartToken}&redirect=${encodeURIComponent("firefox:///")}`;
  } else {
    link = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
  }
  return link;
}

function isHttp(address) {
  return address.includes("http://");
}