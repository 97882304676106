import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { userActions } from '../../actions';
import { validation } from '../../helpers';
import { userSelectors } from '../../selectors';
import Button from '../helper/Button';
import LoadingSpinner from '../helper/LoadingSpinner';
import Input from '../helper/Input';
import { modalConstants } from '../../constants';
import lang from "../../helpers/lang-constants";
import BaseModal from './BaseModal';

const { MODAL_NOT_FOCUSED } = modalConstants;
const { required } = validation;
const { getUpdating } = userSelectors;
const language = lang.customer.components.modal.missing_email_modal;

class MissingEmailModal extends BaseModal {
  render() {
    const { updateEmail, closeModal, isUpdating } = this.props;
    return (
      <div ref={this.targetRef} className="modal content" tabIndex={MODAL_NOT_FOCUSED}>
        <h4 className="dialog-title flex row justify-between">
          {language.title}
          <i
            className="fal fa-times-circle modal-close color-gold"
            onClick={closeModal}
            onKeyPress={closeModal}
            tabIndex={0}
            role="button"
          />
        </h4>
        <div className="dialog-content">
          <span className="content-description">
            {language.description}
          </span>
          <Field
            name="email"
            component={Input}
            type="email"
            validate={validation.email}
            placeholder={language.submit_placeholder}
            validate={[required]}
          />
        </div>
        <div className="dialog-actions flex justify-center">
          <Button type="button" onClick={updateEmail}>
            <LoadingSpinner text={language.submit_button.text} isLoading={isUpdating} />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isUpdating: getUpdating(state, 'updateProfile')
});

const mapDispatchToProps = dispatch => ({
  updateEmail: () => dispatch(userActions.updateUser('missingEmailForm'))
});

export default reduxForm({
  form: 'missingEmailForm'
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MissingEmailModal)
);
