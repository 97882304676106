import React from "react";
import { apiConstants } from "../../constants";

const FILE_NAME = "ABlogo.png";
const { WP_ASSETS } = apiConstants;

// eslint-disable-next-line react/prop-types
const SnackLogo = ({ fields }) => (
  <div className="app-snack">
    <i className="fa fa-times" aria-hidden="true" />
    <a
      href={fields.get("app_android_link")}
      className="android-link"
      target="_blank"
      rel="noopener noreferrer">
      <img
        className="snack-logo"
        src={`${WP_ASSETS}/${FILE_NAME}`}
        alt="android-logo"
      />
    </a>
    <a
      href={fields.get("app_ios_link")}
      className="ios-link"
      target="_blank"
      rel="noopener noreferrer">
      <img
        className="snack-logo"
        src={`${WP_ASSETS}/${FILE_NAME}`}
        alt="ios-logo"
      />
    </a>
    <span>{fields.get("footer_app")}</span>
    <div>
      <a
        href={fields.get("app_android_link")}
        className="android-link app-button"
        target="_blank"
        rel="noopener noreferrer">
        {fields.get("app_button_text")}
      </a>
      <a
        href={fields.get("app_ios_link")}
        className="ios-link app-button"
        target="_blank"
        rel="noopener noreferrer">
        {fields.get("app_button_text")}
      </a>
    </div>
  </div>
);

SnackLogo.propTypes = {
  // See FooterContainer for fields prop-validation
};

export default SnackLogo;
