import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import {
  routesConstants,
  apiConstants,
  externalRoutesConstants,
} from '../../constants';
import HamburgerMenu from './HamburgerMenu';
import Logout from './Logout';
import logoAssistans from '../../assets/images/logo.png';
import logoViking from '../../assets/images/logo_viking.png';
import logoSbf from '../../assets/images/logo_sb.png';
import lang from '../../helpers/lang-constants';
import { themes } from '../../helpers/config';

const { HOME, LOGIN } = routesConstants;
const { WP_ASSETS } = apiConstants;
const language = lang.customer.components.navbar.top_header;

function getLogo() {
  let logo;
  if(themes.isNorway()) {
    logo = logoViking;
  } else if(themes.isAssistans()) {
    logo = logoAssistans;
  } else if(themes.isSbf()) {
    logo = logoSbf;
  } else {
    logo = logoAssistans;
  }
  return logo;
}

const TopHeader = ({ ...props }) => {
  const isLogin = props.history.location.pathname.includes(LOGIN);
  return (
    <header>
      <div id="top-bar" className="top-bar flex row fixed align-center">
        <div className="container flex row align-center justify-between">
          <NavLink to={HOME} activeClassName="site-logo">
            <img src={getLogo()} alt={language.site_name} />
          </NavLink>
          <ul className="flex row justify-end align-center">
            {!themes.isSbf() &&
              <li>
                <a
                  href={externalRoutesConstants.HOME}
                  rel="noopener noreferrer"
                  className={classNames('nav-link color-white', { isLogin })}>
                  {language.title_text}
                </a>
              </li>
            }
            {!isLogin && (
              <li>
                <Logout />
              </li>
            )}
            {!isLogin && (
              <li>
                <HamburgerMenu isLogin={isLogin} />
              </li>
            )}
          </ul>
        </div>
      </div>
    </header>
  );
};
export default TopHeader;
