import insurances from "./types/insurances";

// This is a special type since we need to look at the article code to distinguish between insurances
const DEDUCTIBLE_ARTICLE_TYPE = 'SJF';

export default function handleInsuranceResponse(apiResponse) {
  let activeInsurances = [];
  let inactiveInsurances = [];
  apiResponse.insurances.forEach(insurance => {
    //init some must have values
    insurance.coverage = [];

    let insuranceCoverage = findInsuranceCoverage(insurance);
    if(insuranceCoverage) {
      if(insuranceCoverage.isIgnored) {
        return;
      }
      insurance.coverage = insuranceCoverage.coverage || [];
      insurance.isCoInsurable = insuranceCoverage.isCoInsurable;
      if(insuranceCoverage.shadowType) {
        insurance.articleType = insuranceCoverage.shadowType;
      }
    }
    if(insurance.isActive) {
      activeInsurances.push(insurance);
    } else {
      inactiveInsurances.push(insurance);
    }
  });

  apiResponse.insurances = activeInsurances.concat(inactiveInsurances);

  return apiResponse;
}

function findInsuranceCoverage(insurance) {
  for (const key in insurances) {
    const insuranceType = insurances[key];
    if (insuranceType.types.includes(insurance.articleType)) {
      if(isDeductibleInsurance(insurance) && 
        insurance.articleCode === insuranceType.articleCode) {
          
        return insuranceType;
      } else if(!isDeductibleInsurance(insurance)) {
        return insuranceType;
      }
    }
  }
}

function isDeductibleInsurance(insurance) {
  return insurance.articleType === DEDUCTIBLE_ARTICLE_TYPE;
}
