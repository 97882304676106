import classNames from 'classnames';
import React from 'react';
import ListComponent from '../helper/ListComponent';
import ShoppingCardContainer from './ShoppingCardContainer';

const ShopList = ({ itemIds }) => (
  <div className={classNames('container')}>
    <ListComponent
      ids={itemIds}
      className="shop-items"
      component={ShoppingCardContainer}
      direction="column"
      isInsuranceCard={Boolean(true)}
    />
  </div>
);

export default ShopList;
