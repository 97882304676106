import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { routesConstants } from '../../constants';
import InformationBanner from '../banner/InformationBanner';
import lang from "../../helpers/lang-constants";
import { themes } from "../../helpers/config";

const {
  HOME,
  INSURANCES,
  DETAILS,
  CUSTOMER_SERVICE,
  INVOICES,
  BENEFITS,
  SHOP
} = routesConstants;
const language = lang.customer.components.navbar.side_bar;

const Sidebar = ({ ...rest }) => (
  <nav className={rest.className || ''}>
    <div className="container-nav">
      <ul
        className="nav-list flex column justify-center align-center"
        onClick={rest.onClick ? rest.onClick : undefined}
        onKeyPress={rest.onClick ? rest.onClick : undefined}
        role="button">
        <li>
          <NavLink
            to={HOME}
            exact
            activeClassName="active"
            className="nav-link-sidebar">
            <span>{language.home_text}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={DETAILS}
            activeClassName="active"
            className="nav-link-sidebar">
            <span>{language.my_settings_text}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={INSURANCES}
            activeClassName="active"
            className="nav-link-sidebar">
            <span>{language.my_insurances_text}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={INVOICES}
            activeClassName="active"
            className="nav-link-sidebar">
            <span>{language.my_invoices_text}</span>
          </NavLink>
        </li>
        {
        !themes.isSbf() &&
        <li>
          <NavLink
            to={BENEFITS}
            activeClassName="active"
            className="nav-link-sidebar">
            <span>{language.my_benefits_text}</span>
          </NavLink>
        </li>
        }
        {
        themes.isAssistans() && 
          <li>
            <NavLink
              to={SHOP}
              activeClassName="active"
              className="nav-link-sidebar">
              <span>{language.sign_insurance_text}</span>
            </NavLink>
          </li>
        }
        <li>
          <NavLink
            to={CUSTOMER_SERVICE}
            activeClassName="active"
            className="nav-link-sidebar">
            <span>{language.contact_customer_support_text}</span>
          </NavLink>
        </li>
      </ul>

      {rest.className === 'side-bar' && (
        <InformationBanner
          className="information-banner"
          history={rest.history}
        />
      )}
    </div>
  </nav>
);

export default Sidebar;
