import vehicleConstants from "../../../../constants/vehicle.constants";

const { NOT_COVERED_VEHICLE } = vehicleConstants;
export const PYRAMID_TYPE_MOPED = "MOPED";
export const PYRAMID_TYPE_TGSK = "TGSK";

export default {
  type: NOT_COVERED_VEHICLE,
  matchesType: vehicle => {
    return checkType(vehicle.data.typeCode);
  },
  isInsurable: () => false
};

function checkType(type) {
  switch (type) {
    case PYRAMID_TYPE_MOPED:
      return true;
    case PYRAMID_TYPE_TGSK:
      return true;
    default:
      return false;
  }
}
