import { timeout } from "./utils";

export function fetch(url, requestOptions) {
    if(!requestOptions.retries) {
        return window.fetch(url, requestOptions);
    }
    console.log(`Fetch with retries, trying ${requestOptions.retries} times`);
    let delayMilliseconds = requestOptions.retryDelay || 200;
    const isExponentialDelay = requestOptions.isRetryDelayExponential;
    return new Promise(async (resolve, reject) => {
        let error;
        for(var i = 0; i < requestOptions.retries; i++) {
            console.log(`Fetch attempt ${i + 1}`)
            try {
                resolve(await window.fetch(url, requestOptions));
                return;
            } catch(err) {
                error = err;
            }
            delayMilliseconds = calculateDelay(delayMilliseconds, isExponentialDelay);
            await timeout(delayMilliseconds);
        }
        reject(error);
    });
}

function calculateDelay(delay, isExponential) {
    let factor = isExponential ? 2 : 1;
    return delay * factor;
}