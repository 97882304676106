import { themes } from "../../../helpers/config";
import {
    DetailsForm as DetailsFormAssistans,
    UserDetails as UserDetailsAssistans
} from "./se"
import {
    DetailsForm as DetailsFormNorway,
    UserDetails as UserDetailsNorway
} from "./no"


let DetailsForm;
let UserDetails;

if(themes.isNorway()) {
    DetailsForm = DetailsFormNorway;
    UserDetails = UserDetailsNorway;
} else if(themes.isAssistans()) {
    DetailsForm = DetailsFormAssistans;
    UserDetails = UserDetailsAssistans;
} else {
    DetailsForm = DetailsFormAssistans;
    UserDetails = UserDetailsAssistans;
}

export {
    DetailsForm,
    UserDetails
};