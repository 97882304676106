import { themes } from "../../../../helpers/config";
import * as se_insurances from "./se";

let insurances = [];

if(themes.isAssistans()) {
    insurances = se_insurances;
}

export default insurances;
