import { getFormValues, isInvalid } from "redux-form";
import {
  actions as registerActions,
  selectors as registerSelectors
} from "register";
import { history } from "../helpers";
import {
  apiConstants,
  insuranceConstants,
  errorConstants,
  routesConstants,
  isDirectDebit,
  isCreditCard,
  isInvoice
} from "../constants";
import {
  invoiceSelectors,
  modalSelectors,
  userSelectors,
  insuranceSelectors
} from "../selectors";
import { insuranceService, wpService } from "../services";
import invoiceActions from "./invoices.actions";
import modalActions from "./modal.actions";
import snackbarActions from "./snackbar.actions";
import lang from "../helpers/lang-constants";
import { isAuthorized } from "./functions";

const { SHOPACCEPTDIRECTDEBIT, SHOPACCEPT, SHOPACCEPTCARD } = routesConstants;

const {
  getItemPrice,
  getAmountActiveInsurances,
  getUserInsurances,
  getUserInsurance
} = insuranceSelectors;
const { getPaymentMethod, getPaymentPeriod } = invoiceSelectors;
const {
  getRegisterItems,
  getDiscounts,
  getTotalState,
  getDiscountCode
} = registerSelectors;
const { getUserData } = userSelectors;
const { getInsuranceId, getArticleCode } = modalSelectors;
const {
  startSignNewInsuranceProcess,
  successSignNewInsuranceProcess,
  failureSignNewInsuranceProcess
} = registerActions;
const { closeModal } = modalActions;
const language = lang.customer.components.snack_bar;
const {
  GET_USER_INSURANCES_REQUEST,
  GET_USER_INSURANCES_SUCCESS,
  GET_USER_INSURANCES_FAILURE,
  ADD_CO_INSURED_REQUEST,
  ADD_CO_INSURED_SUCCESS,
  ADD_CO_INSURED_FAILURE,
  GET_ALL_INSURANCES_REQUEST,
  GET_ALL_INSURANCES_SUCCESS,
  GET_ALL_INSURANCES_FAILURE,
  TOGGLE_INSURANCE_CARD,
  SIGN_NEW_INSURANCE_REQUEST,
  SIGN_NEW_INSURANCE_SUCCESS,
  SIGN_NEW_INSURANCE_FAILURE,
  ORDER_KEY_TABS_REQUEST,
  ORDER_KEY_TABS_SUCCESS,
  ORDER_KEY_TABS_FAILURE,
  ADD_CO_INSURED_NORWAY_REQUEST,
  ADD_CO_INSURED_NORWAY_SUCCESS,
  ADD_CO_INSURED_NORWAY_FAILURE,
  CO_INSURANCE_PRICE_LOOKUP_REQUEST,
  CO_INSURANCE_PRICE_LOOKUP_SUCCESS,
  CO_INSURANCE_PRICE_LOOKUP_FAILURE
} = insuranceConstants;
const { SUCCESS_CODE_200, SUCCESS_CODE_201 } = apiConstants;
const ONE_YEAR = 12;
const CUSTOMER_DENIED = 411;
const PYRAMID_ERROR = 402;

const getInsurancesRequest = () => ({ type: GET_USER_INSURANCES_REQUEST });
const getInsurancesSuccess = ({ insurances, keyTabs }) => ({
  type: GET_USER_INSURANCES_SUCCESS,
  insurances,
  keyTabs
});
const getInsurancesFailure = error => ({
  type: GET_USER_INSURANCES_FAILURE,
  error
});

const setAmountActiveInsurance = amount => ({
  type: "SET_AMOUNT_ACTIVE_INSURANCE",
  amount
});

const getInsurances = () => (dispatch, getState) => {
  dispatch(getInsurancesRequest());
  insuranceService
    .getInsurances()
    .then(response => {
      dispatch(getInsurancesSuccess(response));
      dispatch(invoiceActions.setPaymentMethod(response));
      dispatch(invoiceActions.setPaymentPeriod(response));
      const amount = getAmountActiveInsurances(getState());
      dispatch(setAmountActiveInsurance(amount));
    })
    .catch(err => {
      if (!isAuthorized(err)) {
        dispatch(
          snackbarActions.openSnackbar(
            "success",
            errorConstants.SESSION_ERROR_MESSAGE
          )
        );
        return;
      }
      throw err;
    })
    .catch(error => {
      dispatch(getInsurancesFailure(error));
      dispatch(
        snackbarActions.openSnackbar(
          "failure",
          errorConstants.GENERIC_ERROR_MESSAGE
        )
      );
    });
};

const requestShopItems = () => ({ type: GET_ALL_INSURANCES_REQUEST });
const shopItemsSuccess = items => ({ type: GET_ALL_INSURANCES_SUCCESS, items });
const shopItemsFailure = error => ({ type: GET_ALL_INSURANCES_FAILURE, error });

const getShopInsurances = () => dispatch => {
  dispatch(requestShopItems());
  wpService
    .getAcfWpPosts("insurance")
    .then(
      items => dispatch(shopItemsSuccess(items)),
      error => dispatch(shopItemsFailure(errorConstants.SESSION_ERROR_MESSAGE))
    );
};

const signNewInsuranceRequest = () => ({ type: SIGN_NEW_INSURANCE_REQUEST });
const signNewInsuranceSuccess = data => ({
  type: SIGN_NEW_INSURANCE_SUCCESS,
  data
});
const signNewInsuranceFailure = error => ({
  type: SIGN_NEW_INSURANCE_FAILURE,
  error
});

const decrementStep = () => ({ type: "DECREMENT_STEP_COUNTER" });

const signNewInsurance = () => (dispatch, getState) => {
  const register = getRegisterItems(getState());
  const userData = getUserData(getState());
  const paymentMethod = getPaymentMethod(getState());
  let paymentMethodNumber = 4;
  if (isInvoice(paymentMethod)) {
    paymentMethodNumber = 4;
  } else if (isDirectDebit(paymentMethod)) {
    paymentMethodNumber = 1;
  } else if (isCreditCard(paymentMethod)) {
    paymentMethodNumber = 3;
  }
  const paymentPeriod = getPaymentPeriod(getState());
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const discountCode = getDiscountCode(getState());
  dispatch(signNewInsuranceRequest());
  dispatch(startSignNewInsuranceProcess());
  const orderRows = [];
  register.forEach(item => {
    orderRows.push({
      articleCode: item.get("id"),
      customerCode: userData.get("customerCode"),
      discountCode: discountCode || "",
      monthPrice: parseInt(item.get("cost"), 10) / ONE_YEAR
    });
  });
  const payload = {
    orderRows,
    customerCode: userData.get("customerCode"),
    email: userData.get("email"),
    mobilePhoneNumber: userData.get("mobilePhone"),
    firstName: userData.get("firstName"),
    lastName: userData.get("lastName"),
    streetAddress: userData.get("streetAddress"),
    zipCode: userData.get("zipCode"),
    city: userData.get("city"),
    phone: userData.get("phone"),
    paymentMethod: paymentMethodNumber,
    paymentPeriod,
    startDate: tomorrow,
    skipBisnode: true,
    directDebit: paymentMethodNumber !== 3
  };
  if (process.env.NODE_ENV === "development")
    console.log("Payload sign new", payload);
  insuranceService.signNewInsurance(payload).then(response => {
    if (
      response.statusCode === SUCCESS_CODE_200 ||
      response.statusCode === SUCCESS_CODE_201
    ) {
      dispatch(signNewInsuranceSuccess(response));
      dispatch(decrementStep());
      dispatch(successSignNewInsuranceProcess());
      if (paymentMethodNumber === 1) {
        history.push(SHOPACCEPTDIRECTDEBIT);
      } else if (paymentMethodNumber === 3) {
        history.push(`${SHOPACCEPTCARD}`);
      } else {
        history.push(`${SHOPACCEPT}`);
      }
      // dispatch(snackbarActions.openSnackbar("success", response.statusMessage));
    } else {
      dispatch(signNewInsuranceFailure(response.statusMessage));
      dispatch(failureSignNewInsuranceProcess());
      if (
        response.statusCode === CUSTOMER_DENIED ||
        response.statusCode === PYRAMID_ERROR
      ) {
        dispatch(
          snackbarActions.openSnackbar(
            "failure",
            errorConstants.SUSPENDED_ERROR_MESSAGE
          )
        );
      } else {
        dispatch(
          snackbarActions.openSnackbar("failure", response.statusMessage)
        );
      }
    }
  });
};

const addCoInsuredRequest = (insuranceId, articleCode) => ({
  type: ADD_CO_INSURED_REQUEST,
  insuranceId,
  articleCode
});
const addCoInsuredSuccess = (data, insuranceId, articleCode) => ({
  type: ADD_CO_INSURED_SUCCESS,
  data,
  insuranceId,
  articleCode
});
const addCoInsuredFailure = (error, insuranceId, articleCode) => ({
  type: ADD_CO_INSURED_FAILURE,
  error,
  insuranceId,
  articleCode
});
const addCoInsured = () => (dispatch, getState) => {
  const { socialNumber } = getFormValues("coInsuredSe")(getState());
  const invalid = isInvalid("coInsuredSe")(getState());
  if (invalid) return;
  const insuranceNumber = getInsuranceId(getState());
  const articleCode = getArticleCode(getState());
  const userData = getUserData(getState());
  dispatch(addCoInsuredRequest(insuranceNumber, articleCode));
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  if (userData.get("customerCode") === socialNumber) {
    dispatch(
      snackbarActions.openSnackbar(
        "failure",
        language.insurances.add_co_insured.add_self
      )
    );
    return;
  }
  const body = {
    insurance: {
      articleCode: articleCode.toString(),
      monthPrice: 20 // parseInt(insurancePrice, 10)
    },
    coCustomerCode: socialNumber,
    startDate: tomorrow
  };
  if (process.env.NODE_ENV === "development")
    console.log("Payload coInsured", body);
  insuranceService.addCoInsured(body).then(response => {
    if (
      response.statusCode === SUCCESS_CODE_200 ||
      response.statusCode === SUCCESS_CODE_201
    ) {
      dispatch(addCoInsuredSuccess(response, insuranceNumber, articleCode));
      dispatch(closeModal());
      dispatch(
        snackbarActions.openSnackbar(
          "success",
          language.insurances.add_co_insured.add_success
        )
      );
      dispatch(getInsurances());
    } else {
      dispatch(addCoInsuredFailure(response, insuranceNumber, articleCode));
      if (
        response.statusCode === CUSTOMER_DENIED ||
        response.statusCode === PYRAMID_ERROR
      ) {
        dispatch(
          snackbarActions.openSnackbar(
            "failure",
            errorConstants.SUSPENDED_ERROR_MESSAGE
          )
        );
      } else {
        dispatch(
          snackbarActions.openSnackbar(
            "failure",
            errorConstants.ADD_CO_INSURED_ERROR_MESSAGE
          )
        );
      }
    }
  });
};

const addCoInsuredNorwayRequest = (insuranceId, articleCode) => ({
  type: ADD_CO_INSURED_REQUEST,
  insuranceId,
  articleCode
});
const addCoInsuredNorwaySuccess = (data, insuranceId, articleCode) => ({
  type: ADD_CO_INSURED_SUCCESS,
  data,
  insuranceId,
  articleCode
});
const addCoInsuredNorwayFailure = (error, insuranceId, articleCode) => ({
  type: ADD_CO_INSURED_FAILURE,
  error,
  insuranceId,
  articleCode
});
const addCoInsuredNorway = () => (dispatch, getState) => {
  const invalid = isInvalid("coInsuredNorway")(getState());
  if (invalid) return;
  const { firstName, lastName, address, zipcode, city, born } = getFormValues(
    "coInsuredNorway"
  )(getState());
  const coInsuredFormData = getFormValues("coInsuredNorway")(getState());
  const userData = getUserData(getState());
  const insuranceNumber = getInsuranceId(getState());
  const articleCode = getArticleCode(getState());
  dispatch(addCoInsuredRequest(insuranceNumber, articleCode));
  let coInsuredArticleCode = articleCode.toString();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const monthPrice = 590 / 12;

  const body = {
    coUserInfo: {
      firstName: firstName,
      lastName: lastName,
      streetAddress: address,
      zipCode: zipcode,
      city: city,
      born: born
    },
    insurance: {
      articleCode: coInsuredArticleCode,
      monthPrice: monthPrice
    },
    startDate: tomorrow
  };
  insuranceService.addCoInsuredDetailed(body).then(response => {
    if (
      response.statusCode === SUCCESS_CODE_200 ||
      response.statusCode === SUCCESS_CODE_201
    ) {
      dispatch(addCoInsuredSuccess(response, insuranceNumber, articleCode));
      dispatch(closeModal());
      dispatch(
        snackbarActions.openSnackbar(
          "success",
          language.insurances.add_co_insured.add_success
        )
      );
      dispatch(getInsurances());
    } else {
      dispatch(addCoInsuredFailure(response, insuranceNumber, articleCode));
      if (
        response.statusCode === CUSTOMER_DENIED ||
        response.statusCode === PYRAMID_ERROR
      ) {
        dispatch(
          snackbarActions.openSnackbar(
            "failure",
            errorConstants.SUSPENDED_ERROR_MESSAGE
          )
        );
      } else {
        dispatch(
          snackbarActions.openSnackbar(
            "failure",
            errorConstants.ADD_CO_INSURED_ERROR_MESSAGE
          )
        );
      }
    }
  });
};

const orderKeyTabsRequest = () => ({
  type: ORDER_KEY_TABS_REQUEST
});
const orderKeyTabsSuccess = numberOfKeyTabs => ({
  type: ORDER_KEY_TABS_SUCCESS,
  numberOfKeyTabs
});
const orderKeyTabsFailure = () => ({
  type: ORDER_KEY_TABS_FAILURE
});

const orderKeyTabs = () => (dispatch, getState) => {
  const numberOfKeyTabs = getFormValues("orderKeyTabsForm")(getState()).keyTabs;
  dispatch(orderKeyTabsRequest());
  insuranceService.orderKeyTabs({ numberOfKeyTabs }).then(data => {
    // SUCCESS_CODE_201
    if (data.statusCode === SUCCESS_CODE_200) {
      dispatch(orderKeyTabsSuccess(numberOfKeyTabs));
      dispatch(closeModal());
      dispatch(
        snackbarActions.openSnackbar(
          "success",
          language.insurances.order_keytabs.success_1,
          language.insurances.order_keytabs.success_2
        )
      );
    } else {
      dispatch(orderKeyTabsFailure());
      dispatch(snackbarActions.openSnackbar("failure", data.statusMessage));
    }
  });
};

const toggleInsuranceCard = cardId => ({
  type: TOGGLE_INSURANCE_CARD,
  cardId
});

const coInsurancePriceLookupRequest = () => ({
  type: CO_INSURANCE_PRICE_LOOKUP_REQUEST
});
const coInsurancedPriceLookupSuccess = coInsurancePriceData => ({
  type: CO_INSURANCE_PRICE_LOOKUP_SUCCESS,
  coInsurancePriceData
});
const coInsurancedPriceLookupFailure = error => ({
  type: CO_INSURANCE_PRICE_LOOKUP_FAILURE,
  error
});

const coInsurancePriceLookup = insuranceId => (dispatch, getState) => {
  const articleCode = getArticleCode(getState(), insuranceId);
  const coInsuredArticleCode = "2" + articleCode.substring(1);
  const to = getUserInsurance(getState(), insuranceId)
    .first()
    .get("endDate");
  dispatch(coInsurancePriceLookupRequest());
  insuranceService
    .coInsurancePriceLookup(coInsuredArticleCode, to)
    .then(coInsurancePriceData => {
      dispatch(coInsurancedPriceLookupSuccess(coInsurancePriceData));
    })
    .catch(error => {
      dispatch(coInsurancedPriceLookupFailure(error));
    });
};

export default {
  signNewInsurance,
  getInsurances,
  addCoInsured,
  getShopInsurances,
  toggleInsuranceCard,
  orderKeyTabs,
  addCoInsuredNorway,
  coInsurancePriceLookup
};
