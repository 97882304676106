import React from 'react'
import Form from '../../helper/Form'
import Input from '../../helper/Input'
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { validation } from "../../../helpers";
import lang from "../../../helpers/lang-constants";

const language = lang.customer.components.modal.co_insured_modal.form;
const { required, validBornDate, maxLength } = validation;
const maxLength8 = maxLength(8)

let CoInsuredFormNorway = ()=>{
  return(
    <Form className="co-insurance-component co-insurance-no" handleSubmit={() => {}} text="Add coinsured" showButton={false} isRow={false}>
      <Field
        id="firstName"
        label={language.first_name.text}
        name="firstName"
        component={Input}
        type="text"
        placeholder={language.first_name.placeholder}
        validate={required}
      />
      <Field
        id="lastName"
        label={language.last_name.text}
        name="lastName"
        component={Input}
        type="text"
        placeholder={language.last_name.placeholder}
        validate={required}
      />
      <Field
        id="city"
        label={language.city.text}
        name="city"
        component={Input}
        type="text"
        placeholder={language.city.placeholder}
        validate={required}
      />
      <Field
        id="zipcode"
        label={language.zipcode.text}
        name="zipcode"
        component={Input}
        type="text"
        placeholder={language.zipcode.placeholder}
        validate={required}
      />
      <Field
        id="address"
        label={language.address.text}
        name="address"
        component={Input}
        type="text"
        placeholder={language.address.placeholder}
        validate={required}
      />
      <Field
        id="born"
        label={language.born.text}
        name="born"
        component={Input}
        type="text"
        placeholder={language.born.placeholder}
        validate={[validBornDate, maxLength8, required]}
      />
    </Form>
  )
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({

});

CoInsuredFormNorway = reduxForm({
  form: 'coInsuredNorway',
  enableReinitialize: true
})(CoInsuredFormNorway);
export default connect(
  mapStateToProps,
  mapDispatchToProps)(CoInsuredFormNorway)
