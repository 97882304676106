import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Receipt } from "sign-insurance-components";
import Step from "step-counter";
import { insuranceActions, userActions } from "../../actions";
import Heading from "../../components/helper/Heading";
import RegisterContainer from "../../components/shop/RegisterContainer";
import { insuranceSelectors } from "../../selectors";
import ShopContent from "../../components/shop/ShopContent";
import ShopList from "../../components/shop/ShopList";
import SignInsuranceFormContainer from "../../components/shop/SignInsuranceFormContainer";
import LoadingHandler from "./LoadingHandler";
import lang from "../../helpers/lang-constants";

const { getUpdating } = insuranceSelectors;
const language = lang.customer.views.shop_page.index;

const components = [
  { component: ShopList, name: language.steps.choose_insurance_text },
  { component: SignInsuranceFormContainer, name: language.steps.user_info_text }
];

class ShopPage extends PureComponent {
  componentDidMount() {
    const { fetchAllInsurances, fetchInsurances, fetchUserData } = this.props;
    fetchAllInsurances();
    fetchInsurances();
    fetchUserData();
  }

  render() {
    const {
      isLoadingShopItems,
      isLoadingUserInsurances,
      isSigningInsurance
    } = this.props;
    return (
      <div className="container flex column shop">
        <Heading heading={language.title} />
        <Step steps={components} />
        <LoadingHandler />
        {!isLoadingShopItems &&
          !isLoadingUserInsurances &&
          !isSigningInsurance && (
            <div className="content container flex row">
              <ShopContent steps={components} />
              <RegisterContainer />
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoadingShopItems: getUpdating(state, "fetchUserInsurances"),
  isLoadingUserInsurances: getUpdating(state, "fetchShopInsurances"),
  isSigningInsurance: getUpdating(state, "signNewInsurance")
});

const mapDispatchToProps = dispatch => ({
  fetchAllInsurances: () => dispatch(insuranceActions.getShopInsurances()),
  fetchInsurances: () => dispatch(insuranceActions.getInsurances()),
  fetchUserData: () => dispatch(userActions.getUserData())
});

ShopPage.propTypes = {
  fetchAllInsurances: PropTypes.func.isRequired,
  fetchInsurances: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  isLoadingShopItems: PropTypes.bool.isRequired,
  isLoadingUserInsurances: PropTypes.bool.isRequired,
  isSigningInsurance: PropTypes.bool.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopPage);
