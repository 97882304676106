import React from "react";

// eslint-disable-next-line react/prop-types
const FooterCompanyInfo = ({ fields }) => {
  const infoList = fields.get("sub_headings", []).map(subHeading => {
    const heading = subHeading.get("sub_heading");
    return (
      <div className="site-info-column flex column align-start" key={heading}>
        <h5 className="uppercase">{heading}</h5>
        <ul className="company-info-list">
          {subHeading.get("sub_texts", []).map(subText => {
            const text = subText.get("sub_text");
            return <li key={text}>{text}</li>;
          })}
        </ul>
      </div>
    );
  });
  return (
    <div className="container flex justify-center company-info">
      <div className="footer-sub-heading flex row justify-center wrap">
        {infoList}
      </div>
    </div>
  );
};

FooterCompanyInfo.propTypes = {
  // See FooterContainer for fields prop-validation
};

export default FooterCompanyInfo;
