import { createSelector } from "reselect";
import { Map, Set, List } from "immutable";
import { insuranceTypeConstants } from "../constants";

const {
  INSURANCE_CAR_TYPE,
  INSURANCE_CAROLDER_TYPE,
  INSURANCE_LIGHT_TRUCK_TYPE,
  INSURANCE_VETERAN_CAR_TYPE,
  INSURANCE_VETERAN_CAR_SEASON_TYPE,
  INSURANCE_MC_TYPE,
  INSURANCE_MC_OLD_TYPE,
  INSURANCE_MC_SEASON_TYPE,
  INSURANCE_CARAVAN_TYPE,
  INSURANCE_MOBILE_HOME_TYPE,
  INSURANCE_MOBILE_HOME_OLD_TYPE,
  INSURANCE_TRAILER_TYPE,
  INSURANCE_CAR_KEYCHAIN_TYPE,
  INSURANCE_KEYCHAIN_TYPE,
  INSURANCE_VIKING_TYPE,
  INSURANCE_DEDUCTIBLE_CAR_TYPE,
  INSURANCE_DEDUCTIBLE_MC_TYPE,
  INSURANCE_DEDUCTIBLE_MOBILE_HOME_TYPE,
} = insuranceTypeConstants;

const getInsurance = (state, collection, id) => {
  if (!id) return Map();
  return state.getIn([collection, id]);
};

const getUserInsurance = ({ insurances }, articleCode) =>
  getInsurance(insurances, "userInsurances", articleCode);

const getUserInsurances = ({ insurances }) =>
  insurances.get("userInsurances", Map());

const getShopInsurance = ({ insurances }, id) =>
  getInsurance(insurances, "allInsurances", id);

const getShopInsurances = ({ insurances }) =>
  insurances.get("allInsurances", Map());

// const hasInsurance = (state, id) => {
//   let active = false;
//   const userInsurances = getUserInsurances(state);
//   const shopInsuranceTypes = getShopInsurance(state, id).get("article_type");
//   userInsurances.map(userInsurance => {
//     if (userInsurance.get("articleType") === shopInsuranceTypes) {
//       active = true;
//     }
//   });
//   return active;
// };

const getError = ({ insurances }, type) =>
  insurances.getIn(["error", type], "");

const getItemIdGroup = ({ insurances }) =>
  insurances.get("userInsuranceIdGroups", Map());

const getShopInsuranceIds = ({ insurances }) =>
  insurances.get("allInsuranceIds", Set());

const getItemName = ({ insurances }, id) =>
  getInsurance(insurances, "userInsurances", id).get("productName", "");

const getArticleCode = ({ insurances }, id) =>
  getInsurance(insurances, "userInsurances", id).get("articleCode", "");

const getItemPrice = ({ insurances }, id) =>
  getInsurance(insurances, "userInsurances", id).get(
    "monthPriceIncludingVATString"
  );

const getActiveState = ({ insurances }, id) => {
  let active = false;
  const today = new Date();
  const startDateString = getInsurance(insurances, "userInsurances", id).get(
    "startDate"
  );
  const startDate = new Date(startDateString);
  if (getInsurance(insurances, "userInsurances", id).get("isActive", ""))
    active = true;
  else if (startDate > today) active = true;
  return active;
};

const hasActiveInsurance = (state, id) => {
  let active = false;
  const today = new Date();
  const userInsurances = getUserInsurances(state);
  const articleTypes = getShopInsurance(state, id).get("article_types");
  userInsurances.map((usersInsurances) => {
    usersInsurances.map((userInsurance) => {
      const startDateString = userInsurance.get("startDate");
      const startDate = new Date(startDateString);
      if (articleTypes) {
        articleTypes.map((articleType) => {
          if (
            articleType &&
            userInsurance
              .get("articleType")
              .includes(articleType.get("article_type"))
          ) {
            if (userInsurance.get("isActive")) active = true;
            else if (startDate > today) active = true;
          }
        });
      }
    });
  });
  return active;
};

const getAmountActiveInsurances = (state) => {
  let activeAmount = 0;
  const today = new Date();
  const userInsurances = getUserInsurances(state);
  userInsurances.map((userInsurance) => {
    userInsurance.map((insurance) => {
      const startDateString = insurance.get("startDate");
      const startDate = new Date(startDateString);
      if (insurance.get("isActive") && insurance.get("articleType") !== "NB1")
        activeAmount++;
      else if (startDate > today) activeAmount++;
    });
  });
  return activeAmount;
};

const hasOnlyKeyTabInsurance = (state) => {
  let isOnlyKeyTabs = true;

  const userInsurances = getUserInsurances(state);
  userInsurances.map((insurance) => {
    insurance.map((item) => {
      const articleType = item.get("articleType", "");

      if (articleType !== "NB1") {
        isOnlyKeyTabs = false;
      }
    });
  });
  return isOnlyKeyTabs;
};

const getArticleType = ({ insurances }, id) =>
  getInsurance(insurances, "userInsurances", id).get("articleType", "");

const getItemInsuranceNumber = ({ insurances }, id) =>
  getInsurance(insurances, "userInsurances", id).get("insuranceNumber", "");

const getCustomerCode = ({ insurances }, id) =>
  getInsurance(insurances, "userInsurances", id).get("customerCode", "");

const getItemIds = ({ insurances }) => insurances.get("userInsuranceIds");

const getUpdating = ({ insurances, modal }, type) => {
  if (type !== "insuranceId")
    return insurances.getIn(["updating", type], false);
  return insurances.getIn(
    ["updating", modal.get("insuranceId", ""), modal.get("articleCode", "")],
    false
  );
};

// const hasInsuranceState = () =>
//   createSelector(
//     [hasInsurance, getUserInsurances],
//     (shopItem, userInsurances) => {
//       let found = false;
//       found = userInsurances.some(
//         insurance => shopItem.get("title") === insurance.get("productName")
//       );
//       return found;
//     }
//   );

const getInsuranceTypesState = () =>
  createSelector([getUserInsurances], (insurances) => {
    let types = Set();
    insurances.forEach((insurance) => {
      const productName = insurance.get("productName", "");
      if (productName.length && productName.toLowerCase().includes("väg"))
        types = types.add(productName);
    });
    return types;
  });

const getItemGroupByOrderNumberState = () =>
  createSelector(
    [getUserInsurances, getItemIdGroup, (state, props) => props],
    (insurances, ids, orderNumber) => {
      let res = Map();
      const group = ids.get(orderNumber, Map());
      if (!group) return res;
      group.forEach((id) => {
        insurances.get(orderNumber).map((insurance) => {
          if (insurance.get("articleCode") === id) {
            res = res.set(id, insurance);
          }
        });
      });
      return res;
    }
  );

const getItemGroupState = () =>
  createSelector(
    [getUserInsurances, (state, props) => props],
    (items, insuranceGroup) => {
      let res = Map();
      insuranceGroup.forEach((val) => {
        items.map((item) => {
          item.map((insurance) => {
            if (insurance.get("articleCode") === val)
              res = res.set(val, insurance);
          });
        });
      });
      return res;
    }
  );
const getKeyTabInsuranceState = () =>
  createSelector([getUserInsurances], (itemsList) => {
    let keyTabKeys = List();
    itemsList.map((userInsurances) => {
      userInsurances.forEach((val) => {
        if (val.get("insuranceNumber").includes("B"))
          keyTabKeys = keyTabKeys.push(val);
      });
    });
    return keyTabKeys;
  });

const getInsuranceIcon = (articleType) => {
  let insuranceIcon;
  if (
    articleType === INSURANCE_CAR_TYPE ||
    articleType === INSURANCE_CAROLDER_TYPE ||
    articleType === INSURANCE_DEDUCTIBLE_CAR_TYPE
  ) {
    insuranceIcon = "icon-car";
  } else if (articleType === INSURANCE_LIGHT_TRUCK_TYPE) {
    insuranceIcon = "icon-truck";
  } else if (
    articleType === INSURANCE_VETERAN_CAR_TYPE ||
    articleType === INSURANCE_VETERAN_CAR_SEASON_TYPE
  ) {
    insuranceIcon = "icon-veterancar";
  } else if (
    articleType === INSURANCE_MC_TYPE ||
    articleType === INSURANCE_MC_OLD_TYPE ||
    articleType === INSURANCE_MC_SEASON_TYPE ||
    articleType === INSURANCE_DEDUCTIBLE_MC_TYPE
  ) {
    insuranceIcon = "icon-motorbike";
  } else if (articleType === INSURANCE_CARAVAN_TYPE) {
    insuranceIcon = "icon-housetrailer";
  } else if (
    articleType === INSURANCE_MOBILE_HOME_TYPE ||
    articleType === INSURANCE_MOBILE_HOME_OLD_TYPE ||
    articleType === INSURANCE_DEDUCTIBLE_MOBILE_HOME_TYPE
  ) {
    insuranceIcon = "icon-motorhome";
  } else if (articleType === INSURANCE_TRAILER_TYPE) {
    insuranceIcon = "icon-trailer";
  } else if (
    articleType === INSURANCE_CAR_KEYCHAIN_TYPE ||
    articleType === INSURANCE_KEYCHAIN_TYPE
  ) {
    insuranceIcon = "icon-keys";
  } else if (articleType === INSURANCE_VIKING_TYPE) {
    insuranceIcon = "icon-viking_logo";
  }
  return insuranceIcon;
};

const getCoInsurancePrice = ({ insurances }) =>
  insurances.get("coInsurancePrice", Map());

export default {
  getShopInsurance,
  getShopInsurances,
  getShopInsuranceIds,
  getItemGroupState,
  getItemIdGroup,
  getItemIds,
  getUserInsurances,
  getItemName,
  getActiveState,
  getArticleType,
  getUserInsurance,
  getKeyTabInsuranceState,
  hasActiveInsurance,
  getItemGroupByOrderNumberState,
  getUpdating,
  getError,
  getItemInsuranceNumber,
  getInsuranceTypesState,
  getCustomerCode,
  getItemPrice,
  getArticleCode,
  getInsuranceIcon,
  getAmountActiveInsurances,
  hasOnlyKeyTabInsurance,
  getCoInsurancePrice,
};
