import React from "react";
import { connect } from "react-redux";
import { 
  bankIdActions,
} from "../../../actions";
import BankIdLoginForm from "./BankIdLoginForm";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";

const BankIdLogin = ( { requestBankIdAuth } ) => {
  return (
      <BankIdLoginForm
        requestBankIdAuth={requestBankIdAuth}
      />
  );
}

const mapDispatchToProps = dispatch => {
  const { bankIdAuthenticate } = bankIdActions;
  return {
    requestBankIdAuth: () => {
      dispatch(bankIdAuthenticate());
    }
  };
};

BankIdLogin.propTypes = {
  requestBankIdAuth: PropTypes.func.isRequired,
  loadState: ImmutablePropTypes.mapContains({
    bankIdAuth: PropTypes.bool,
  }).isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(BankIdLogin);