import PropTypes from "prop-types";
import React from "react";
import { Field, reduxForm } from "redux-form";
import { validation } from "../../../helpers";
import Button from "../../helper/Button";
import Input from "../../helper/Input";
import LoadingSpinner from "../../helper/LoadingSpinner";
import lang from "../../../helpers/lang-constants";

const { required, number } = validation;
const language = lang.customer.components.login.sms.sms_login_form;

const SmsLoginForm = ({
  requestSmsToken,
  handleSubmit,
  isLoading
}) => {
  return (
    <form
      text="Logga in"    
      className="login-form flex column align-center justify-center"
    >
      <div className="login-form-content">
        <Field
          name="login"
          component={Input}
          placeholder={language.placeholder}
          type="tel"
          showErrorOnSubmit
          autoFocus
          validate={[number, required]}
          autoComplete="off"
        />
      </div>
      <div className="flex column justify-center align-center">
        <Button
          type="submit"
          onClick={handleSubmit(() => requestSmsToken())}
          disabled={isLoading}
        >
          <LoadingSpinner isLoading={isLoading} text={language.submit_button.text} />
        </Button>
      </div>
    </form>
  );
};

SmsLoginForm.propTypes = {
  requestSmsToken: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default reduxForm({
  form: "loginForm"
})(SmsLoginForm);
