import React from "react";
import { apiConstants } from "../../constants";
import footerWaveViking from '../../assets/images/footer-wave.png';
import footerWaveSbf from '../../assets/images/footer-wave-sbf.png';
import { themes } from '../../helpers/config';


const { WP_ASSETS } = apiConstants;

function getFooterWave() {
  let wave;
  if(themes.isNorway()) {
    wave = footerWaveViking;
  } else if(themes.isAssistans()) {
    wave = `${WP_ASSETS}/footer-wave.png`;
  } else if(themes.isSbf()) {
    wave = footerWaveSbf;
  }else {
    wave = `${WP_ASSETS}/footer-wave.png`;
  }
  return wave;
}

const FooterWave = () => (
  <img
    className="img-divider"
    src={getFooterWave()}
    alt="wave"
  />
);

export default FooterWave;
