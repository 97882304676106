import invoiceService from "./invoice.service";
import userService from "./user.service";
import familyService from "./family.service";
import bankidService from "./bankid.service";
import smsService from "./sms.service";
import insuranceService from "./insurance.service";
import wpService from "./wp.service";
import loginService from "./login.service";

export {
  invoiceService,
  userService,
  bankidService,
  insuranceService,
  wpService,
  smsService,
  familyService,
  loginService
};
