import { vehicleConstants } from "../../../../../constants";

const {
    PERSONAL_VEHICLE
} = vehicleConstants;

export default {
    types: [
        'BF',
        'BF1'
    ],
    coverage: [
        PERSONAL_VEHICLE
    ],
    isCoInsurable: true
}
