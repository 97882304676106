import React from "react";
import { connect } from "react-redux";
import { actions as shopActions, selectors as shopSelectors } from "register";
import { ShoppingCard } from "sign-insurance-components";
import { insuranceActions } from "../../actions";
import { insuranceSelectors } from "../../selectors";

const { getRegisterItem, isMutualExcludedState } = shopSelectors;
const {
  getShopInsurance,
  getShopInsurances,
  hasActiveInsurance,
  getItemIdGroup,
} = insuranceSelectors;

class ShoppingCardContainer extends React.PureComponent {
  componentDidUpdate() {
    const { isExcluded, showMutualExclusionText } = this.props;
    if (isExcluded) {
      showMutualExclusionText();
    }
  }

  render() {
    const {
      shopInsurance,
      shopInsurances,
      hasInsurance = false,
      toggleCard,
      addToCart,
      isInCart,
      id,
      itemIdGroup,
      isExcluded,
      isSoloCard,
    } = this.props;
    let excluded = isExcluded;
    itemIdGroup.map((item) => {
      item.map((itemId) => {
        shopInsurances.map((shopItem) => {
          if (
            shopItem.get("article_code") === itemId &&
            shopItem.get("excludes")
          ) {
            shopItem.get("excludes").map((shopExclude) => {
              if (shopExclude.get("article_code") === id) excluded = true;
            });
          }
        });
      });
    });
    const hasAddedDeductibleInsurance = shopInsurance
      .get("article_types")
      .some((item) => item.get("article_type").includes("SJF"));
    return (
      <div>
        <ShoppingCard
          item={shopInsurance}
          toggleCard={toggleCard}
          addToCart={(cost, title) =>
            addToCart(
              cost,
              title,
              shopInsurance.get("excludes", false),
              hasAddedDeductibleInsurance
            )
          }
          hasInsurance={hasInsurance}
          isInCart={isInCart}
          isExcluded={excluded}
          isForceExpanded={isSoloCard}
        />
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const isMutualExcluded = isMutualExcludedState();
  return (state, { id }) => ({
    shopInsurance: getShopInsurance(state, id),
    shopInsurances: getShopInsurances(state),
    hasInsurance: hasActiveInsurance(state, id),
    isInCart: getRegisterItem(state, id),
    isExcluded: isMutualExcluded(state, id),
    itemIdGroup: getItemIdGroup(state),
  });
};

const mapDispatchToProps = (dispatch, { id }) => ({
  toggleCard: () => dispatch(insuranceActions.toggleInsuranceCard(id)),
  addToCart: (cost, title, excludes, hideNotificationText) =>
    dispatch(
      shopActions.addToCart(id, cost, title, excludes, hideNotificationText)
    ),
  showMutualExclusionText: (type) => {
    dispatch(shopActions.showMutualExclusionText(type));
  },
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(ShoppingCardContainer);
