export default {
  GET_INVOICES_REQUEST: "GET_INVOICES_REQUEST",
  GET_INVOICES_SUCCESS: "GET_INVOICES_SUCCESS",
  GET_INVOICES_FAILURE: "GET_INVOICES_FAILURE",
  CHANGE_PAYMENT_METHOD_TEMPORARY: "CHANGE_PAYMENT_METHOD_TEMPORARY",
  CHANGE_PAYMENT_METHOD_REQUEST: "CHANGE_PAYMENT_METHOD_REQUEST",
  CHANGE_PAYMENT_METHOD_SUCCESS: "CHANGE_PAYMENT_METHOD_SUCCESS",
  CHANGE_PAYMENT_METHOD_FAILURE: "CHANGE_PAYMENT_METHOD_FAILURE",
  SET_PAYMENT_METHOD: "SET_PAYMENT_METHOD",
  SET_PAYMENT_PERIOD: "SET_PAYMENT_PERIOD",
  SET_TEMP_PAYMENT_METHOD: "SET_TEMP_PAYMENT_METHOD",
  SET_TEMP_PAYMENT_PERIOD: "SET_TEMP_PAYMENT_PERIOD"
};

export function isInvoice(paymentMethod) {
  let method = paymentMethod.toLowerCase();
  return method === "pappersfaktura se" ||
        method === "faktura" ||
        method === "papirfaktura";
}

export function isDirectDebit(paymentMethod) {
  let method = paymentMethod.toLowerCase();
  return method === "autogiro" || method === "avtalegiro" || method === "autogiro se";
}

export function isCreditCard(paymentMethod) {
  let method = paymentMethod.toLowerCase();
  return method === "kort" || method === "kor" ||
    //YEP THEY HAVE EVEN MISSPELLED KORTBETALNING!!!!!!!!
    method === "kortbetaling" ||
    //GUARD IF SOMEONE GETS THE IDEA THEY WANNA FIX THE TYPO
    method === "kortbetalning";
}

export function isNetsProvider(provider) {
  return provider.toLowerCase() === 'nets';
}