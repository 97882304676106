import analytics_se from "./se/analytics";
import analytics_no from "./no/analytics";
import { themes } from "../../helpers/config";
import BaseAnalytics from "./base_analytics.skeleton";

let analytics = new BaseAnalytics();
if (themes.isAssistans()) {
  analytics = analytics_se;
} else if (themes.isNorway()) {
  analytics = analytics_no;
}

export default analytics;
