import { Iterable } from "immutable";
import { applyMiddleware, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import thunkMiddleware from "redux-thunk";
import rootSaga from "./root-saga";
import rootReducer from "../reducers";

const loggerMiddleware = createLogger({
  stateTransformer: state => {
    const newState = {};

    for (const i of Object.keys(state)) {
      if (Iterable.isIterable(state[i])) {
        newState[i] = state[i].toJS();
      } else {
        newState[i] = state[i];
      }
    }
    return newState;
  }
});
// This is needed since 'sign-insurance-components' is using saga
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    process.env.NODE_ENV === "development"
      ? applyMiddleware(thunkMiddleware, loggerMiddleware, sagaMiddleware)
      : applyMiddleware(thunkMiddleware, sagaMiddleware)
  )
);

sagaMiddleware.run(rootSaga);

export default store;
