import classNames from "classnames";
import React, { PureComponent } from "react";
import {
  PaymentMethodDescription,
  paymentMethods as getPaymentMethods,
  SelectedPayment,
  InfoText
} from "sign-insurance-components";
import { connect } from "react-redux";
import Heading from "../../components/helper/Heading";
import { invoiceActions, userActions, insuranceActions } from "../../actions";
import { invoiceSelectors, insuranceSelectors } from "../../selectors";
import ListComponent from "../../components/helper/ListComponent";
import InvoiceCard from "../../components/invoice/InvoiceCard";
import LoadingSpinner from "../../components/helper/LoadingSpinner";
import Parser from "html-react-parser";
import lang from "../../helpers/lang-constants";
import {
  isCreditCard,
  isDirectDebit,
  isInvoice
} from "../../constants";

const {
  getLoading,
  getItemIdsState,
  getTempPaymentMethod,
  getTempPaymentPeriod,
  getPaymentPeriod,
  setTempPaymentPeriod,
  setTempPaymentMethod,
  getIframeUrl,
  getPaymentMethod
} = invoiceSelectors;
const language = lang.customer.views.invoice_page.index;

const { getUpdating } = insuranceSelectors;
class InvoicePage extends PureComponent {
  componentDidMount() {
    const { fetchInvoices, fetchUserData, fetchInsurances } = this.props;
    fetchInvoices();
    fetchUserData();
    fetchInsurances();
  }

  componentDidUpdate() {
    const { iframeUrl } = this.props;
    if (iframeUrl && iframeUrl.length) window.open(iframeUrl, "_self");
  }

  render() {
    const {
      isLoading,
      loadingInsurances,
      itemsIds,
      selectedPayment,
      selectedPeriod,
      setPaymentMethod,
      selectedTempPayment,
      setPaymentPeriod,
      previousPeriod,
      updatePayment
    } = this.props;
    const paymentMethods = getPaymentMethods();
    if (isInvoice(selectedPayment)) {
      paymentMethods[1].description = language.payment_method_override_text;
    }
    
    const paymentById = [];
    paymentMethods.forEach(method => {
      paymentById[method.paymentMethod] = method;
    });

    const previousSelectedPayment =
      isInvoice(selectedPayment)
        ? 2
        : isDirectDebit(selectedPayment)
        ? 1
        : 2;
    return (
      <div
        className={classNames("container invoices", {
          loading: isLoading || loadingInsurances
        })}
      >
        <Heading heading={language.heading_text} />
        <LoadingSpinner size={25} isLoading={isLoading || loadingInsurances} />
        {selectedTempPayment && (!isLoading && !loadingInsurances) && (
          <div className="invoice-payment-section">
            {previousSelectedPayment === 1 && (
              <div className={classNames("direct-debit-text")}>
                <p>
                  {
                    Parser(lang.formatString(language.cancel_direct_debit.text, 
                      `<a href="tel:${language.cancel_direct_debit.telephone_number.replace(/ /g, "")}">
                        ${language.cancel_direct_debit.telephone_number}
                      </a>`))
                  }
                </p>
              </div>
            )}
            <PaymentMethodDescription
              paymentMethods={paymentMethods}
              selectedPayment={selectedTempPayment}
              previousSelectedPayment={previousSelectedPayment}
              setPaymentMethod={setPaymentMethod}
              loggedIn={Boolean(true)}
            />
            {(selectedTempPayment && paymentById[selectedTempPayment] && paymentById[selectedTempPayment].paymentPeriod.length > 0) && (
            <InfoText
              text={language.info_text.text}
              description={language.info_text.description}
              bottom="40px"
              left="-7px"
            />
            )}
            <SelectedPayment
              faded={selectedTempPayment !== 1}
              paymentMethods={paymentMethods}
              selectedPayment={
                selectedTempPayment === 1 ? 1 : previousSelectedPayment
              }
              selectedPeriod={
                selectedTempPayment === 1 ? selectedPeriod : previousPeriod
              }
              setPaymentPeriod={setPaymentPeriod}
            />
            <div
              className={classNames("update-button", {
                "disabled-option": selectedTempPayment !== 1
              })}
            >
              <button
                type="button"
                className={classNames(
                  "button flex align-center justify-center button-primary"
                )}
                disabled={selectedTempPayment !== 1}
                onClick={updatePayment}
              >
                {language.apply_direct_debit_button.text}
              </button>
            </div>
          </div>
        )}
        {itemsIds.size > 0 && !loadingInsurances && (
          <ListComponent
            ids={itemsIds}
            component={InvoiceCard}
            direction="column"
            isLoading={isLoading}
            ulClass='invoice-list'
            liClass='invoice-list-element'
          />
        )}
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getInvoiceIds = getItemIdsState();
  return state => ({
    isLoading: getLoading(state),
    itemsIds: getInvoiceIds(state),
    selectedTempPayment: getTempPaymentMethod(state),
    selectedPayment: getPaymentMethod(state),
    selectedPeriod: getTempPaymentPeriod(state),
    previousPeriod: getPaymentPeriod(state),
    iframeUrl: getIframeUrl(state),
    loadingInsurances: getUpdating(state, "fetchUserInsurances")
  });
};

const mapDispatchToProps = dispatch => ({
  fetchInvoices: () => dispatch(invoiceActions.getInvoices()),
  fetchUserData: () => dispatch(userActions.getUserData()),
  fetchInsurances: () => dispatch(insuranceActions.getInsurances()),
  setPaymentMethod: (value, method, provider) =>
    dispatch(setTempPaymentMethod(value, method, provider)),
  setPaymentPeriod: value => dispatch(setTempPaymentPeriod(value)),
  updatePayment: () => dispatch(invoiceActions.updatePaymentMethod())
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(InvoicePage);
